
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import { Link } from 'gatsby';
import React, { Component, useEffect, useState, useRef } from 'react';
import { Accordion, Button, Card, Col, Row, Table, DropdownButton, Dropdown } from 'react-bootstrap';
import { FaPlus, FaTrashAlt, FaGripVertical, FaCaretDown,FaCircleNotch } from "react-icons/fa";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { GoKebabVertical } from "react-icons/go";
import Select, { components } from 'react-select';
import styled from 'styled-components';
import config from '../../../aws-exports';
import Move from '../../../assets/move.svg';
import DDLoader from '../../../assets/pageload.gif';
import EditI from '../../../assets/edit_tm.svg';
import Notes from '../Notes';
import { FaInfoCircle } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { Menu, MenuItem, MenuButton, SubMenu } from '@szhsin/react-menu';
import ScopeTypes from "../../../constants/ScopeTypes";
import { createDocLineItem, removeUsersManufacturers, saveUsersManufacturers, getAlgorithm, getDefaultManufacturer, deleteLineItem, updateLineItemWorkOrder, addDocSubLineItem, saveLineItmAsDefault } from "../../../graphql/queries";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";
import { isEmpty } from '../../CommonComponents/helper';
import ScopeUnits from '../../../constants/ScopeUnits';
import Trash from '../../../assets/trash.svg';
import Edit from '../../../assets/manufacture-edit.svg';
import Tick from '../../../assets/manufacture-tick.svg';
import Sync from "../../../assets/sync.gif";
import SaveLineAsDefault from '../../Modals/SaveLineAsDefault.jsx';
import secureLocalStorage from "react-secure-storage";

Amplify.configure(config);
const CaretDownIcon = () => {
    return <FaCaretDown color={'#000'} />;
};

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <CaretDownIcon />
        </components.DropdownIndicator>
    );
};
export default function LineItem(props) {
    const { consoleLog } = require('../../commonFunctions.js');
    consoleLog("LineItem props: ", props);
    consoleLog("LineItem userManufacturers: ", props.userManufacturers); //userManufacturers
    // consoleLog("LineItem defaultManufacturers: ", props.defaultManufacturers); //defaultManufacturers

    const updatedStructure = useSelector((state) => state.templateStructure);
    const [stData, setStData] = React.useState(useSelector((state) => state.templateStructure));
    const [structureIndex, setStructureIndex] = React.useState(props.structureIndex)
    const [categoryId, setCategoryId] = React.useState(props.categoryId);
    let df_units = useSelector((state) => state.defaultUnits);
    consoleLog('def>>>', df_units);
    const [defaultUnits, setDefaultUnits] = React.useState(df_units);
    const [UNITS, setUnits] = React.useState([]);
    const dispatch = useDispatch();

    const [line_item_quantity, set_line_item_quantity] = React.useState(props.line_item.quantity);
    const [line_item_unit, set_line_item_unit] = React.useState(props.line_item.unit);
    const [line_item_convert_to, set_line_item_convert_to] = React.useState(props.line_item.convert_to);
    const [line_item_algo_unit, set_line_item_algo_unit] = React.useState('SQ');

    const [line_item_ratio_from, set_line_item_ratio_from] = React.useState(props.line_item.ratio_from);
    const [line_item_ratio_to, set_line_item_ratio_to] = React.useState(props.line_item.ratio_to);
    const [line_item_convert_round, set_line_item_convert_round] = React.useState(props.line_item.convert_round);
    const [line_item_qty_input, set_line_item_qty_input] = React.useState(true);

    const [line_item_cost, set_line_item_cost] = React.useState(Number.parseFloat(props.line_item.cost).toFixed(2));
    const [line_item_manufecturer, set_line_item_manufecturer] = React.useState(props.line_item.manufacturer);
    const [line_item_color, set_line_item_color] = React.useState(props.line_item.color ? props.line_item.color : '');
    const [line_item_waste, set_line_item_waste] = React.useState(Number.parseFloat(props.line_item.waste).toFixed(2));

    const inputRef = useRef(null);

    let ratio_fromx = props.line_item.ratio_from ? props.line_item.ratio_from : 1;
    let ratio_tox = props.line_item.ratio_to ? props.line_item.ratio_to : 1;
    let quantityx = 1 * props.line_item.quantity;
    let wastex = 1 * props.line_item.waste;
    let convert_roundx = 1 * props.line_item.convert_round;
    let quant = quantityx * ratio_tox / ratio_fromx;
    let adjusted_quantityx = ((100 + wastex) * quant) / 100;
    if (convert_roundx) {
        adjusted_quantityx = Math.ceil(adjusted_quantityx);
        adjusted_quantityx = adjusted_quantityx.toFixed(2);
        adjusted_quantityx = Number.parseFloat(adjusted_quantityx);
    } else {
        adjusted_quantityx = Math.round(adjusted_quantityx * 100) / 100;
        adjusted_quantityx = adjusted_quantityx.toFixed(2);
        adjusted_quantityx = Number.parseFloat(adjusted_quantityx);
    }
    consoleLog('adjusted_quantityx', adjusted_quantityx);
    // const [line_item_adjusted_quantity, set_line_item_adjusted_quantity] = React.useState(props.line_item.adjusted_quantity == 0 && props.line_item.quantity > 0 ? Number.parseFloat(props.line_item.quantity+(props.line_item.quantity*props.line_item.waste/100)).toFixed(2) : props.line_item.adjusted_quantity);
    const [line_item_adjusted_quantity, set_line_item_adjusted_quantity] = React.useState(adjusted_quantityx);

    let net_costx = 0;
    if (adjusted_quantityx == 0) {
        net_costx = props.line_item.cost * props.line_item.quantity;
    } else {
        net_costx = props.line_item.cost * adjusted_quantityx;
    }
    const [line_item_net_cost, set_line_item_net_cost] = React.useState(Number.parseFloat(net_costx).toFixed(2));

    const [line_item_labor_cost, set_line_item_labor_cost] = React.useState(props.line_item.labor_cost);
    const [line_item_material_cost, set_line_item_material_cost] = React.useState(props.line_item.material_cost);
    const [line_item_overhead, set_line_item_overhead] = React.useState(props.line_item.overhead);
    const [line_item_profit, set_line_item_profit] = React.useState(props.line_item.profit);
    const [line_item_adjusted_cost, set_line_item_adjusted_cost] = React.useState(0);
    const [applyOPIsChecked, setApplyOPIsChecked] = useState(props.line_item.apply_op);
    const [hideUnitPriceColumn, setHideUnitPriceColumn] = useState(props.line_item.remove_prices);
    const [lineItemName, setLineItemName] = React.useState(props.line_item.name);
    const [editLineItem, toggleEditLineItem] = React.useState(false);
    const [roundToggle, setRoundToggle] = React.useState(false);
    const [showAdvanceOpt, toggleShowAdvanceOpt] = React.useState(false);
    const [lineItemChange, setLineItemChange] = React.useState(props.line_item.name);
    const [isNameUpdated, setIsNameUpdated] = React.useState(false);
    const [showReport, toggleShowReport] = React.useState(false);
    const getSantizedSublineItem = (sublineItem) =>{
        consoleLog("getSantizedSublineItem:",sublineItem);
        try {
            sublineItem = sublineItem
            .replace(/^\[|\]$/g, "")
            .split(",")
            .map(item => `"${item.replace(/"/g, '\\"').replace(/'/g, "'")}"`)
            .join(",");
          sublineItem = `[${sublineItem}]`;
          sublineItem = JSON.parse(sublineItem);
        } catch (error) {
          sublineItem = [];
        }
        consoleLog("getSantizedSublineItem: return",sublineItem);
        return sublineItem;
    };
    const [line_item, set_line_item] = React.useState(props.line_item ? props.line_item : []);
    let subLineItems = props.line_item.sub_line_item;
    if (!subLineItems || subLineItems === "[]") {
        subLineItems = [];
    }
    else if (typeof subLineItems == 'string') {
        subLineItems = getSantizedSublineItem(props.line_item.sub_line_item);
    }
    const [sub_line_item, set_sub_line_item] = React.useState(subLineItems)
    const [isChecked, setIsChecked] = useState(false);
    const [showCheckBox, toggleShowCheckBox] = React.useState(false);
    const [reportUnits, setReportUnits] = React.useState([]);

    const [ddLoader, setDdLoader] = React.useState(false);
    // const [dragPositionNotes, setDragPositionNotes]  = useState(sub_line_item)

    const [onBlurFlag, setOnBlurFlag] = React.useState(true);

    const [reportType, setReportType] = React.useState([]);
    const [algoValue, setAlgoValue] = React.useState('');
    const [selectedReportType, setSelectedReportType] = useState(null); // null initially since it's dynamic
    const [selectedReportUnit, setSelectedReportUnit] = useState(null); // null initially since it's dynamic

    const [showSaveLineAsDefaultModal, setShowSaveLineAsDefaultModal] = useState(false);

    const [synching, setSynching] = useState(false);
    const showSynching = async () => {
        setSynching(true);
        await new Promise((resolve) => setTimeout(resolve, 2000));  // Simulate save delay
        setSynching(false);
    };
    function checkNumber(x) {
        if (typeof x == 'number' && !isNaN(x) && x > -1) {
            if (Number.isInteger(x)) {
                console.log(`checkNumber ${x} is integer.`);
                return true;
            }
            else {
                console.log(`checkNumber ${x} is a float value.`);
                return true;
            }
        } else {
            console.log(`checkNumber ${x} is not a number`);
            return false;
        }
    }

    useEffect(() => {
        setStData(updatedStructure);
        setCategoryId(props.categoryId);
        // set_line_item(props.line_item ? props.line_item : []);
        // let subLineItems = props.line_item.sub_line_item;
        // if(!subLineItems || subLineItems === "[]"){
        //     subLineItems = [];
        // }
        // if(typeof props.url_params.doc_id != 'undefined'){
        //     if(typeof subLineItems == 'string'){
        //         subLineItems = JSON.parse(props.line_item.sub_line_item);
        //         // console.log('useEffect sub_line_item xxxx');
        //     }
        // }
        // set_sub_line_item(subLineItems);
        // console.log('useEffect sub_line_item>>>',sub_line_item);
        // console.log('useEffect sub_line_item typeof>>>', typeof sub_line_item);
        // console.log('useEffect sub_line_item json parse>>>', JSON.parse(sub_line_item));
        setStructureIndex(props.structureIndex);
        consoleLog("Strucpostture on useEffect sturctureIndex: ", structureIndex);

        // setting default units as per require format
        let d_units = [{ value: "", label: "(None)" }];
        for (let i = 1; i < defaultUnits.length; i++) {
            consoleLog("defaultUnits: ", defaultUnits[i]);
            // let unit_label = defaultUnits[i].name + ' ' + defaultUnits[i].label;
            let unit_label = (defaultUnits[i].name + ' - ' + defaultUnits[i].label).replace(/\s*\(.*?\)\s*/g, '');
            d_units.push({ value: defaultUnits[i].name, label: unit_label });
        }
        d_units.sort((a, b) => a.label.localeCompare(b.label));
        consoleLog("d_units: ", d_units);
        setUnits(d_units);
        let report_types = [{ value: "(None)", label: "(None)" }];
        for (let i = 0; i < ScopeTypes.length; i++) {
            report_types.push({
                value: ScopeTypes[i].id,
                label: ScopeTypes[i].title,
            });
        }
        setReportType(report_types);
        let algoVal = getAlgoValue(props.line_item.algorithm);
        if(stData?.quantities && algoVal){
            if(algoVal.value.quantity>0){
                handleChangeScopeUnit(algoVal);
            }
        }

    }, [updatedStructure,
        props.categoryId,
        props.line_item,
        props.line_item.sub_line_item,
        structureIndex]
    );

    const calculateQuantity = (val) => {
        consoleLog("handleLIQuantityChangeValue value: ", val);
        let allData = stData;

        let li_cost = allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.doc_line_item_id == line_item.doc_line_item_id).cost;
        if (li_cost == '' || li_cost == null) {
            li_cost = 0;
        }
        li_cost = 1 * li_cost;
        consoleLog("handleLIQuantityChangeValue li_cost: ", li_cost);

        let li_waste = allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.doc_line_item_id == line_item.doc_line_item_id).waste;
        if (li_waste == '' || li_waste == null) {
            li_waste = 0;
        }
        li_waste = 1 * li_waste;
        consoleLog("handleLIQuantityChangeValue li_waste: ", li_waste);

        let percentage = (li_waste / 100) * val;

        percentage = +val + +percentage;
        percentage = Math.round(percentage * 100) / 100;
        let r_percentage = percentage.toFixed(2);
        r_percentage = Number.parseFloat(percentage);

        var prod = r_percentage * li_cost;
        let final_cost = Number.parseFloat(prod).toFixed(2);

        allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.doc_line_item_id == line_item.doc_line_item_id).net_cost = final_cost;
        set_line_item_net_cost(final_cost);

        allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.doc_line_item_id == line_item.doc_line_item_id).quantity = val;
        set_line_item_quantity(val);
        line_item.quantity = val;

        allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.doc_line_item_id == line_item.doc_line_item_id).adjusted_quantity = r_percentage;
        set_line_item_adjusted_quantity(r_percentage);

        dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });

        props.calculateSubTotal(allData);

    };

    const handleLIQuantityChangeValue = (e) => {
        let val = e.target.value;
        if (e.target.value == '' || e.target.value == null) {
            val = 0.00;
        }
        const decimalPattern = /^\d*\.?\d{0,3}$/; // Allows up to 3 decimal places
        // Check if the input matches the pattern (up to 3 decimal places)
        if (!decimalPattern.test(val)) {
            return;
        }

        consoleLog("handleLIQuantityChangeValue value: ", val);
        let allData = stData;
        let line_item_filter_on = checkDocumentUniqueID();
        set_line_item_qty_input(true);

        allData.structures[structureIndex].categories.find(c => c.category_id == categoryId).line_items.find(l => l[line_item_filter_on] == line_item[line_item_filter_on]).quantity = val;
        set_line_item_quantity(val);
        line_item.quantity = val;

        dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
        calculateLIAdjQuantity(undefined, undefined, val, undefined);
    }

    const handleLIConvertToChangeTo = (e) => {
        consoleLog("handleLIConvertToChangeTo e: ", e);
        consoleLog("handleLIConvertToChangeTo l: ", line_item_unit);
        let value = '';
        if (e.name) {
            value = e.name;
        }
        else if (e.value) {
            value = e.value;
        }
        consoleLog("handleLIConvertToChangeTo v: ", value);

        updateConvertToUnit(value, 'convert_to');
    }

    const handleLIConvertToChange = (e) => {
        consoleLog("handleLIConvertToChange e: ", e);
        if (line_item_convert_to != '') {
            props.setShow(true);
            props.setShowMessage("This line item is using a unit converter. To change the unit, click the Edit button and set the Conversion to (None).");
            props.setShowMessageType("Oops!");
            return false;
        }
        let value = '';
        if (e.name) {
            value = e.name;
        }
        else if (e.value) {
            value = e.value;
        }

        updateConvertToUnit(value, 'unit');
    }

    const updateConvertToUnit = (value, func) => {
        let allData = stData;
        let line_item_filter_on = checkDocumentUniqueID();
        if (func == 'convert_to') {
            set_line_item_convert_to(value);
            line_item.convert_to = value;
            allData.structures[structureIndex].categories.find(c => c.category_id == categoryId).line_items.find(l => l[line_item_filter_on] == line_item[line_item_filter_on]).convert_to = value;
            if (value == '') {
                set_line_item_unit(line_item_algo_unit);
                line_item.unit = line_item_algo_unit;
                allData.structures[structureIndex].categories.find(c => c.category_id == categoryId).line_items.find(l => l[line_item_filter_on] == line_item[line_item_filter_on]).unit = line_item_algo_unit;
            } else {
                set_line_item_unit(value);
                line_item.unit = value;
                allData.structures[structureIndex].categories.find(c => c.category_id == categoryId).line_items.find(l => l[line_item_filter_on] == line_item[line_item_filter_on]).unit = value;
            }

        } else {
            set_line_item_unit(value);
            line_item.unit = value;
            allData.structures[structureIndex].categories.find(c => c.category_id == categoryId).line_items.find(l => l[line_item_filter_on] == line_item[line_item_filter_on]).unit = value;
        }
        dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
    }

    const handleLIRatioFromChange = (e) => {
        consoleLog("handleLIRatioFromChange e", e);

        let value = e.target.value;

        if (e.target.value == '' || e.target.value == null) {
            value = 0.00;
        }

        const decimalPattern = /^\d*\.?\d{0,3}$/; // Allows up to 3 decimal places
        // Check if the input matches the pattern (up to 3 decimal places)
        if (!decimalPattern.test(value)) {
            return;
        }

        set_line_item_ratio_from(value);

        line_item_qty_input ? calculateLIAdjQuantity(value, undefined, undefined, undefined, undefined) : calculateLIQuantity(value, undefined, undefined, undefined, undefined);
    }

    const handleLIRatioToChange = (e) => {
        consoleLog("handleLIRatioToChange e", e);

        let value = 1 * e.target.value;

        set_line_item_ratio_to(value);

        line_item_qty_input ? calculateLIAdjQuantity(undefined, value, undefined, undefined, undefined) : calculateLIQuantity(undefined, value, undefined, undefined, undefined);
    }

    const updateUnit = (value) => {
        set_line_item_unit(value)
        line_item.unit = value

        let allData = stData;
        allData.structures[structureIndex].categories.find(c => c.category_id == categoryId).line_items.find(l => l.doc_line_item_id == line_item.doc_line_item_id).unit = value;
        dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });

        setRoundToggle(true)
    }

    const updateAlgo = (value) => {
        let allData = stData;
        let line_item_filter_on = checkDocumentUniqueID();
        allData.structures[structureIndex].categories.find(c => c.category_id == categoryId).line_items.find(l => l[line_item_filter_on] == line_item[line_item_filter_on]).algorithm = value;
        dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
    }

    const getAlgoValue = (algorithm) => {
        let algoobject = {value:{algo:"",quantity:0,unit:""}};
        if (!isEmpty(algorithm)) {
            let constantValue = true;
            let quantities = stData.quantities;

            if (typeof quantities === 'string') {
                quantities = JSON.parse(stData.quantities);
            }

            let structure = quantities && quantities.length
                ? quantities.find(quantity => quantity.structure_id == props.structureId)
                : null;
            let selectedValue = {};
            if (!isEmpty(structure)) {

                for (const scope of structure.scopes) {
                    for (const [key, value] of Object.entries(scope.quantities)) {
                        if (key === algorithm) {
                            constantValue = false;
                            set_line_item_algo_unit(value.unit);
                            setAlgoValue(`${value.name} (${value.quantity} ${value.unit})`);
                            selectedValue = value;
                            break;
                        }
                    }
                }
            }
            if (constantValue) {
                for (const type of Object.keys(ScopeUnits)) {
                    for (const [key, value] of Object.entries(ScopeUnits[type])) {
                        if (key === algorithm) {
                            set_line_item_algo_unit(value.unit);
                            setAlgoValue(`${value.name} (${value.quantity} ${value.unit})`);
                            selectedValue = value;
                            break;
                        }
                    }
                }
            }
            if(selectedValue){
                algoobject.value.algo = selectedValue.algo;
                algoobject.value.label = selectedValue.name;
                algoobject.value.quantity = selectedValue.quantity;
                algoobject.value.unit = selectedValue.unit;
                algoobject.value.custom = 1;
            }
        }
        return algoobject;
    }

    const handleLIManufecturerChangeValue = (e) => {
        setCustomDropdown(false)
        consoleLog("handleLIManufecturerChangeValue e.target.value: ", e.value, e.label);
        if(e.value == ''){
            set_line_item_manufecturer('');
            line_item.manufacturer = '';
            return;
        }
        set_line_item_manufecturer(e.label);
        line_item.manufacturer = e.label;

        let allData = stData;
        let line_item_filter_on = checkDocumentUniqueID();
        allData.structures[structureIndex].categories.find(c => c.category_id == categoryId).line_items.find(l => l[line_item_filter_on] == line_item[line_item_filter_on]).manufacturer = e.label;
        dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
        consoleLog('handleLIManufecturerChangeValue allData>', allData);
        setStData(allData);
    }
    const handleLIColorPicker = (e) => {
        consoleLog("handleLIColorPicker e.target.value: ", e.target.value);
        set_line_item_color(e.target.value);
        line_item.color = e.target.value;

        let allData = stData;
        let line_item_filter_on = checkDocumentUniqueID();
        allData.structures[structureIndex].categories.find(c => c.category_id == categoryId).line_items.find(l => l[line_item_filter_on] == line_item[line_item_filter_on]).color = e.target.value;
        dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });

    }
    const checkDocumentUniqueID = () =>{
        let line_item_filter_on = "line_item_id"; 
        if (line_item?.doc_line_item_id) {
            line_item_filter_on = "doc_line_item_id";
        }
        return line_item_filter_on;
    }
    const handleLICostChangeValue = (e) => {
        let val = e.target.value;
        consoleLog("handleLICostChangeValue e.target.value: ", e.target.value);
        if (e.target.value == '' || e.target.value == null) {
            val = 0.00;
        }
        const decimalPattern = /^\d*\.?\d{0,3}$/; // Allows up to 3 decimal places
        // Check if the input matches the pattern (up to 3 decimal places)
        if (!decimalPattern.test(val)) {
            return;
        }
        let allData = stData;
        let line_item_filter_on = checkDocumentUniqueID();
        let li_adj_quantity = allData.structures[structureIndex].categories.find(c => c.category_id == categoryId).line_items.find(l => l[line_item_filter_on] == line_item[line_item_filter_on]).adjusted_quantity;
        if (li_adj_quantity == '' || li_adj_quantity == null) {
            li_adj_quantity = 0;
        }
        consoleLog("handleLICostChangeValue li_adj_quantity: ", li_adj_quantity);

        consoleLog("handleLIProfitChangeValue line_item_adjusted_quantity: ", line_item_adjusted_quantity);
        // if(li_adj_quantity==0 && line_item_adjusted_quantity > 0){
        li_adj_quantity = line_item_adjusted_quantity;
        // allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.doc_line_item_id == line_item.doc_line_item_id).adjusted_quantity = line_item_adjusted_quantity;
        // }

        li_adj_quantity = 1 * li_adj_quantity;

        let net_cost = val * li_adj_quantity;
        net_cost = Number.parseFloat(net_cost).toFixed(2);
        consoleLog("handleLICostChangeValue net_cost: ", net_cost);

        allData.structures[structureIndex].categories.find(c => c.category_id == categoryId).line_items.find(l => l[line_item_filter_on] == line_item[line_item_filter_on]).cost = val;
        set_line_item_cost(val);
        line_item.cost = val;

        allData.structures[structureIndex].categories.find(c => c.category_id == categoryId).line_items.find(l => l[line_item_filter_on] == line_item[line_item_filter_on]).net_cost = net_cost;
        set_line_item_net_cost(net_cost);

        dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
    }

    const handleLINetCostChangeValue = (e) => {
        consoleLog("handleLINetCostChangeValue e.target.value: ", e.target.value);
        set_line_item_net_cost(e.target.value)
        line_item.net_cost = e.target.value;

        let allData = stData;
        let line_item_filter_on = checkDocumentUniqueID();
        allData.structures[structureIndex].categories.find(c => c.category_id == categoryId).line_items.find(l => l[line_item_filter_on] == line_item[line_item_filter_on]).net_cost = e.target.value;
        dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
    }

    const handleLIWasteFactorChangeValue = (e) => {
        let val = e.target.value;
        consoleLog("handleLIWasteFactorChangeValue e.target.value: ", e.target.value);
        if (e.target.value == '' || e.target.value == null) {
            val = 0.00;
        }
        const decimalPattern = /^\d*\.?\d{0,3}$/; // Allows up to 3 decimal places
        // Check if the input matches the pattern (up to 3 decimal places)
        if (!decimalPattern.test(val)) {
            return;
        }
        let allData = stData;
        let line_item_filter_on = checkDocumentUniqueID();
        allData.structures[structureIndex].categories.find(c => c.category_id == categoryId).line_items.find(l => l[line_item_filter_on] == line_item[line_item_filter_on]).waste = val;
        set_line_item_waste(val);
        line_item.waste = val;

        line_item_qty_input ? calculateLIAdjQuantity(undefined, undefined, undefined, val, undefined) : calculateLIQuantity(undefined, undefined, undefined, val, undefined);

        dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
    }

    const handleLINameChange = (e) => {
        let val = e.target.innerText;
        console.log("handleLINameChange New", val);
        console.log("handleLINameChange Existing", line_item.name);
        if(line_item.name != val){
            setIsNameUpdated(true);
        }else{
            setIsNameUpdated(false);
        }
        let allData = stData;
        let line_item_filter_on = checkDocumentUniqueID();
        allData.structures[structureIndex].categories.find(c => c.category_id == categoryId).line_items.find(l => l[line_item_filter_on] == line_item[line_item_filter_on]).name = val;
        setLineItemChange(val);
        line_item.name = val;
    }
    const handleLIAdjQuantityChangeValue = (e) => {
        let val = e.target.value;
        consoleLog("handleLIAdjQuantityChangeValue e.target.value: ", e.target.value);
        if (e.target.value == '' || e.target.value == null) {
            val = 0.00;
        }
        const decimalPattern = /^\d*\.?\d{0,3}$/; // Allows up to 3 decimal places
        // Check if the input matches the pattern (up to 3 decimal places)
        if (!decimalPattern.test(val)) {
            return;
        }

        consoleLog("handleLIAdjQuantityChangeValue val: ", val);
        let allData = stData;

        set_line_item_qty_input(true);

        allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.doc_line_item_id == line_item.doc_line_item_id).adjusted_quantity = val;
        set_line_item_adjusted_quantity(val);
        set_line_item(prev => ({ ...prev, adjusted_quantity: val }));
        calculateLINetCost(val, line_item_cost);
        calculateLIQuantity(undefined, undefined, val, undefined, undefined);
    }

    const calculateLIQuantity = (ratio_from = line_item_ratio_from, ratio_to = line_item_ratio_to, adjusted_quantity = line_item_adjusted_quantity, waste = line_item_waste, convert_round = line_item_convert_round) => {

        consoleLog("calculateLIQuantity");

        ratio_from = ratio_from ? ratio_from : 1;
        ratio_to = ratio_to ? ratio_to : 1;
        adjusted_quantity = 1 * adjusted_quantity;
        waste = 1 * waste;
        convert_round = 1 * convert_round;

        consoleLog("calculateLIQuantity ratio_from", ratio_from);
        consoleLog("calculateLIQuantity ratio_to", ratio_to);
        consoleLog("calculateLIQuantity adjusted_quantity", adjusted_quantity);
        consoleLog("calculateLIQuantity waste", waste);

        let x = adjusted_quantity * ratio_from / ratio_to;
        let quantity = (100 * x) / (100 + waste);

        quantity = Number.parseFloat(quantity).toFixed(2);
        consoleLog("calculateLIQuantity quantity", quantity);

        let allData = stData;
        let line_item_filter_on = checkDocumentUniqueID();
        let currentLI = allData.structures[structureIndex].categories.find(c => c.category_id == categoryId).line_items.find(l => l[line_item_filter_on] == line_item[line_item_filter_on]);

        currentLI.quantity = quantity;
        currentLI.ratio_from = ratio_from;
        currentLI.ratio_to = ratio_to;
        currentLI.waste = waste;
        currentLI.convert_round = convert_round;
        currentLI.adjusted_quantity = adjusted_quantity;

        set_line_item_quantity(quantity);

        props.calculateSubTotal(allData);
        dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
    }

    const calculateLIAdjQuantity = (ratio_from = line_item_ratio_from, ratio_to = line_item_ratio_to, quantity = line_item_quantity, waste = line_item_waste, convert_round = line_item_convert_round) => {

        consoleLog("calculateLIAdjQuantity");

        ratio_from = ratio_from ? ratio_from : 1;
        ratio_to = ratio_to ? ratio_to : 1;
        quantity = 1 * quantity;
        waste = 1 * waste;
        convert_round = 1 * convert_round;

        consoleLog("calculateLIAdjQuantity ratio_from", ratio_from);
        consoleLog("calculateLIAdjQuantity ratio_to", ratio_to);
        consoleLog("calculateLIAdjQuantity quantity", quantity);
        consoleLog("calculateLIAdjQuantity waste", waste);

        let x = quantity * ratio_to / ratio_from;
        let adjusted_quantity = ((100 + waste) * x) / 100;

        if (convert_round) {
            adjusted_quantity = Math.ceil(adjusted_quantity);
            adjusted_quantity = adjusted_quantity.toFixed(2);
            adjusted_quantity = Number.parseFloat(adjusted_quantity);
        }
        else {
            adjusted_quantity = Math.round(adjusted_quantity * 100) / 100;
            adjusted_quantity = adjusted_quantity.toFixed(2);
            adjusted_quantity = Number.parseFloat(adjusted_quantity);
        }
        consoleLog("calculateLIAdjQuantity adjusted_quantity", adjusted_quantity);
        calculateLINetCost(adjusted_quantity, line_item_cost);

        let allData = stData;
        let line_item_filter_on = checkDocumentUniqueID();
        let currentLI = allData.structures[structureIndex].categories.find(c => c.category_id == categoryId).line_items.find(l => l[line_item_filter_on] == line_item[line_item_filter_on]);

        currentLI.quantity = quantity;
        currentLI.ratio_from = ratio_from;
        currentLI.ratio_to = ratio_to;
        currentLI.waste = waste;
        currentLI.convert_round = convert_round;
        currentLI.adjusted_quantity = adjusted_quantity;

        set_line_item_adjusted_quantity(adjusted_quantity);

        props.calculateSubTotal(allData);
        dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
    }

    const calculateLINetCost = (qty, cost) => {
        consoleLog("calculateLINetCost");
        let net_cost = (+qty) * (+cost);
        net_cost = Number.parseFloat(net_cost).toFixed(2);
        consoleLog("calculateLINetCost net_cost", net_cost);

        let allData = stData;
        let line_item_filter_on = checkDocumentUniqueID();
        allData.structures[structureIndex].categories.find(c => c.category_id == categoryId).line_items.find(l => l[line_item_filter_on] == line_item[line_item_filter_on]).net_cost = net_cost;

        set_line_item_net_cost(net_cost);
        dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
    }

    const handleLINearestCUChangeValue = (e) => {
        consoleLog("handleLINearestCUChangeValue e: ", e);

        let value = + e.target.checked;
        set_line_item_convert_round(value);
        line_item.convert_round = value;

        consoleLog("handleLINearestCUChangeValue convert_round", value);

        line_item_qty_input ? calculateLIAdjQuantity(undefined, undefined, undefined, undefined, value) : calculateLIQuantity(undefined, undefined, undefined, undefined, value);
    }
    const handleLILaborCostChangeValue = (e) => {
        consoleLog("handleLILaborCostChangeValue e.target.value: ", e.target.value);
        set_line_item_labor_cost(e.target.value)
        line_item.labor_cost = e.target.value

        let allData = stData;
        allData.structures[structureIndex].categories.find(c => c.category_id == categoryId).line_items.find(l => l.doc_line_item_id == line_item.doc_line_item_id).labor_cost = e.target.value;
        dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
    }
    const handleLIMaterialCostChangeValue = (e) => {
        consoleLog("handleLIMaterialCostChangeValue e.target.value: ", e.target.value);
        set_line_item_material_cost(e.target.value)
        line_item.material_cost = e.target.value

        let allData = stData;
        allData.structures[structureIndex].categories.find(c => c.category_id == categoryId).line_items.find(l => l.doc_line_item_id == line_item.doc_line_item_id).material_cost = e.target.value;
        dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
    }
    const handleLIOverheadChangeValue = (e) => {
        consoleLog("handleLIOverheadChangeValue e.target.value: ", e.target.value);
        set_line_item_overhead(e.target.value)
        line_item.overhead = e.target.value

        let allData = stData;
        allData.structures[structureIndex].categories.find(c => c.category_id == categoryId).line_items.find(l => l.doc_line_item_id == line_item.doc_line_item_id).overhead = e.target.value;
        dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
    }
    const handleLIProfitChangeValue = (e) => {
        consoleLog("handleLIProfitChangeValue e.target.value: ", e.target.value);
        set_line_item_profit(e.target.value)
        line_item.profit = e.target.value

        let allData = stData;
        allData.structures[structureIndex].categories.find(c => c.category_id == categoryId).line_items.find(l => l.doc_line_item_id == line_item.doc_line_item_id).profit = e.target.value;
        dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
    }
    const handleLIAdjCostChangeValue = (e) => {
        consoleLog("handleLIAdjCostChangeValue e.target.value: ", e.target.value);
        set_line_item_adjusted_cost(e.target.value)

        line_item.adjusted_cost = e.target.value
        let allData = stData;
        allData.structures[structureIndex].categories
            .find(c => c.category_id == categoryId)
            .line_items.find(
                l => l.doc_line_item_id == line_item.doc_line_item_id).adjusted_cost = e.target.value;
        dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
    }
    const handleApplyOPIsChecked = () => {
        let copy = applyOPIsChecked;
        setApplyOPIsChecked(!applyOPIsChecked);
        let val = 0;
        if (copy == false || copy == "0") {
            val = 1;
        }
        // if(copy == true || copy == '1'){
        //     let val = 0;
        // }
        let allData = stData;
        allData.structures[structureIndex].categories
            .find(c => c.category_id == categoryId)
            .line_items.find(
                l => l.doc_line_item_id == line_item.doc_line_item_id
            ).apply_op = val;
        consoleLog("handleApplyOPIsChecked", applyOPIsChecked);
        consoleLog("handleApplyOPIsChecked copy", copy);
        consoleLog("handleApplyOPIsChecked val", val);

        dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
    }

    const handleHideUnitPriceColumn = () => {
        consoleLog("handleHideUnitPriceColumn");
        let copy = hideUnitPriceColumn;
        setHideUnitPriceColumn(!hideUnitPriceColumn);
        let val = 0;
        if (copy == false || copy == "0") {
            val = 1;
        }
        let allData = stData;
        allData.structures[structureIndex].categories
            .find(c => c.category_id == categoryId)
            .line_items.find(
                l => l.doc_line_item_id == line_item.doc_line_item_id
            ).remove_prices = val;
        consoleLog("handleHideUnitPriceColumn", hideUnitPriceColumn);
        consoleLog("handleHideUnitPriceColumn copy", copy);
        consoleLog("handleHideUnitPriceColumn val", val);

        dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
    }

    const handleOnChangeCheckBox = () => {
        setIsChecked(!isChecked);
    };

    // add new subline item / note
    const addNoteFunc = () => {
        var sub_line_item_copy = [...sub_line_item, ""];
        set_sub_line_item(sub_line_item_copy);
        consoleLog("addNoteFunc sub_line_item_copy: ", sub_line_item_copy);

        let allData = stData;
        let line_item_filter_on = checkDocumentUniqueID();
        consoleLog("addNoteFunc allData: ", allData);
        allData.structures[structureIndex].categories.find(c => c.category_id == categoryId).line_items.find(l => l[line_item_filter_on] == line_item[line_item_filter_on]).sub_line_item = [...sub_line_item_copy];
        dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
    };

    // on remove subline item
    async function handleSubLineItemChange(sub_line_item_index, line_item_id) {
        consoleLog("handleSubLineItemChange sub_line_item_index: " + sub_line_item_index + ", line_item_id: " + line_item_id)
        var sub_line_item_copy = [...sub_line_item];
        sub_line_item_copy.splice(sub_line_item_index, 1);
        consoleLog("sub_line_item_copy: ", sub_line_item_copy)
        set_sub_line_item(sub_line_item_copy);

        let allData = stData;
        let line_item_filter_on = checkDocumentUniqueID();
        allData.structures[structureIndex].categories.find(c => c.category_id == categoryId).line_items.find(l => l[line_item_filter_on] == line_item[line_item_filter_on]).sub_line_item = [...sub_line_item_copy];

        if (typeof props.url_params.doc_id != 'undefined') {

            consoleLog("handleSubLineItemChange doc_line_item_id", line_item.doc_line_item_id);
            consoleLog("handleSubLineItemChange sub_line_item_copy", sub_line_item_copy);
            try {
                showSynching();
                const add_sub_line_item = await API.graphql({
                    query: addDocSubLineItem,
                    variables: {
                        doc_line_item_id: line_item.doc_line_item_id,
                        sub_line_item: JSON.stringify(sub_line_item_copy)
                    }
                });
                consoleLog("handleSubLineItemChange add_sub_line_item", add_sub_line_item);
            } catch (err) {
                console.error(err);
            }
        }

        dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });

        if (typeof props.url_params.doc_id != 'undefined') {
            props.updateDocOnElasticSearch();
        }
    }

    // input text change
    async function handleAddNote(index, value) {
        consoleLog("handleAddNote sub_line_item_index: ", index)
        var sub_line_item_copy = [...sub_line_item];
        sub_line_item_copy[index] = value;
        set_sub_line_item(sub_line_item_copy);

        let allData = stData;
        let line_item_filter_on = checkDocumentUniqueID();
        allData.structures[structureIndex].categories.find(c => c.category_id == categoryId).line_items.find(l => l[line_item_filter_on] == line_item[line_item_filter_on]).sub_line_item = [...sub_line_item_copy];

        if (typeof props.url_params.doc_id != 'undefined') {

            consoleLog("handleAddNote doc_line_item_id", line_item.doc_line_item_id);
            consoleLog("handleAddNote sub_line_item_copy", sub_line_item_copy);
            try {
                showSynching();
                const add_sub_line_item = await API.graphql({
                    query: addDocSubLineItem,
                    variables: {
                        doc_line_item_id: line_item.doc_line_item_id,
                        sub_line_item: JSON.stringify(sub_line_item_copy)
                    }
                });
                consoleLog("handleAddNote add_sub_line_item", add_sub_line_item);
            } catch (err) {
                console.error(err);
            }
        }
        dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });

        if (typeof props.url_params.doc_id != 'undefined') {
            props.updateDocOnElasticSearch();
        }
    }

    // on remove line item
    function handleRemoveLineItem(line_item_id) {
        consoleLog('handleRemoveLineItem: line_item_id: ', line_item_id)
        toggleShowAdvanceOpt(!showAdvanceOpt)
        toggleEditLineItem(!editLineItem)

        // var sub_line_item_copy = [...sub_line_item];
        // sub_line_item_copy.splice(0);
        // consoleLog("sub_line_item_copy: ", sub_line_item_copy)
        // set_sub_line_item(sub_line_item_copy);

        props.onChange(line_item_id);
    }

    async function handleOnDragEndStructureNotes(results) {
        consoleLog('handleOnDragEndStructureNotes');
        if (!results.destination) return;
        const itemsNotes = Array.from(sub_line_item);
        const [reorderedItemNotes] = itemsNotes.splice(results.source.index, 1);
        itemsNotes.splice(results.destination.index, 0, reorderedItemNotes);
        //setDragPositionNotes(itemsNotes);
        set_sub_line_item(itemsNotes);
        let allData = stData;
        let line_item_filter_on = checkDocumentUniqueID();
        allData.structures[structureIndex].categories.find(c => c.category_id == categoryId).line_items.find(l => l[line_item_filter_on] == line_item[line_item_filter_on]).sub_line_item = [...itemsNotes];
        dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
        setStData(allData);
        consoleLog("handleOnDragEndStructureNotes itemsNotes: ", itemsNotes);
        if (typeof props.url_params.doc_id != 'undefined') {
            props.updateDocOnElasticSearch();
            try {
                showSynching();
                const add_sub_line_item = await API.graphql({
                    query: addDocSubLineItem,
                    variables: {
                        doc_line_item_id: line_item.doc_line_item_id,
                        sub_line_item: JSON.stringify(itemsNotes)
                    }
                });
                consoleLog("handleOnDragEndStructureNotes add_sub_line_item:", add_sub_line_item);
            } catch (err) {
                console.error(err);
            }
        }
    }

    const handleLReportTypeChangeValue = (e) => {
        setSelectedReportType(e);
        consoleLog("handleLReportTypeChangeValue e: ", e);
        if (e.label !== '(None)') {
            fetchAlgo(e.label);
        }
        setSelectedReportUnit({ label: '(None)', value: '(None)' });
        setAlgoValue('None');
    };

    async function fetchAlgo(scopeType) {
        setDdLoader(true);

        let scopes = [];
        let type = scopeType;
        if (scopeType === 'RoofScopeX' || scopeType === 'RoofScope+') {
            type = 'RoofScope';
        }

        let reportUnits = [{ value: "(None)", label: "(None)" }];
        let scopetUnits = ScopeUnits[type];

        if (scopeType === 'RoofScope+') {
            scopetUnits = { ...scopetUnits, ...ScopeUnits['GutterScope'] }
        }

        if (!isEmpty(stData.quantities)) {
            let quantities = stData.quantities;

            if (typeof quantities === 'string') {
                quantities = JSON.parse(stData.quantities);
            }

            let structure = quantities && quantities.length
                ? quantities.find(quantity => quantity.structure_id == props.structureId)
                : null;

            if (!isEmpty(structure)) {
                scopes = structure.scopes;
                let quantityList = scopes && scopes.length
                    ? scopes.find(scope => scope.scope_type_name.toLowerCase() == type.toLowerCase())
                    : null;

                if (!isEmpty(quantityList)) {
                    scopetUnits = { ...scopetUnits, ...quantityList.quantities };
                }

                if (scopeType === 'RoofScope+') {
                    let gutterScopeList = scopes && scopes.length
                        ? scopes.find(scope => scope.scope_type_name.toLowerCase() == 'gutterscope')
                        : null;
                    if (!isEmpty(gutterScopeList)) {
                        scopetUnits = { ...scopetUnits, ...gutterScopeList.quantities };
                    }
                }
            }
        }

        for (const [key, value] of Object.entries(scopetUnits)) {
            if (scopeType === 'RoofScopeX' && key !== 'total_squares' && isNaN(parseInt(value.name))) {
                continue;
            }
            reportUnits.push({
                value: value,
                label: `${value.name} (${value.quantity} ${value.unit})`,
            });
        }

        if (scopes.length > 0 && type !== 'RoofScope') {
            let roofScopeList = scopes && scopes.length
                ? scopes.find(scope => scope.scope_type_name.toLowerCase() == 'roofscope')
                : null;
            if (!isEmpty(roofScopeList)) {
                for (const [key, value] of Object.entries(roofScopeList.quantities)) {
                    if (isNaN(parseInt(value.name))) {
                        continue;
                    }
                    reportUnits.push({
                        value: value,
                        label: `${value.name} (${value.quantity} ${value.unit})`,
                    });
                }
            }
        }

        setReportUnits(reportUnits);

        setDdLoader(false);
    }

    const handleChangeScopeUnit = (e) => {
         
        setSelectedReportUnit(e);
        consoleLog("handleChangeScopeUnit e: ", e);
        if (typeof e.value === 'object') {
            let val = e.value;
            let qty = parseFloat(val.quantity);
            let algo = val.algo;
            let unit = val.unit;

            calculateQuantity(qty);
            updateAlgo(algo);

            set_line_item_algo_unit(e.value.unit);
            if(!val.custom){
                setAlgoValue(e.label);
            }
        }
        else if (e.label == '(None)') {
            setAlgoValue('None');
        }
    };

    async function deleteLineItm(line_item_id) { // Delete a line item
        //consoleLog('deleteLineItm>', doc_line_item_id);
        //consoleLog('deleteLineItm>', structureIndex);
        consoleLog('deleteLineItm>', line_item_id);
        consoleLog('deleteLineItm>', line_item.line_item_id);
        let allData = { ...stData };
        let line_data = allData.structures[props.structureIndex].categories.find(c => c.category_id == props.categoryId).line_items.filter(l =>  l.doc_line_item_id != line_item.doc_line_item_id);
        consoleLog('deleteLineItm>', line_data);
        allData.structures[props.structureIndex].categories.find(c => c.category_id == props.categoryId).line_items = line_data;
        // allData.structures[structureIndex].categories = allData.structures[structureIndex].categories.filter((c) => c.category_id != props.category.category_id);
        // consoleLog('deleteLineItm>', JSON.stringify(allData));
        dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
        setStData(allData);
        if (typeof props.url_params.doc_id != 'undefined') {
            try {
                showSynching();
                const delete_line_item = await API.graphql({
                    query: deleteLineItem,
                    variables: {
                        doc_line_item_id: line_item_id,
                    },
                });
                consoleLog('deleteLineItm>', delete_line_item);
                props.updateDocOnElasticSearch();
            } catch (err) {
                console.error(err);
            }
        }
    }
    const [isDisabled, setIsDisabled] = useState(false);
    async function saveLineItemAsDefault(force_update) { //Save a line item as default
        setIsDisabled(true);
        console.log("saveLineItemAsDefault force_update", force_update);
        let client_id = JSON.parse(secureLocalStorage.getItem("client_data")).client_id;
        let team_id = JSON.parse(secureLocalStorage.getItem("client_data")).team_id;
        let allData = stData;
        let line = allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.doc_line_item_id == line_item.doc_line_item_id);
        consoleLog('saveLineItemAsDefault line', line);
        consoleLog('saveLineItemAsDefault line.doc_id', line.doc_id);
        consoleLog('saveLineItemAsDefault props.url_params.doc_id', props.url_params.doc_id);
        console.log("saveLineItemAsDefault line.doc_line_item_id", line.doc_line_item_id);
    
        consoleLog('saveLineItemAsDefault client_id', client_id);
        consoleLog('saveLineItemAsDefault team_id', team_id);
        consoleLog('saveLineItemAsDefault quantity', line_item_quantity);
        consoleLog('saveLineItemAsDefault unit', line_item_unit);
        consoleLog('saveLineItemAsDefault convert_to', line_item_convert_to);
        consoleLog('saveLineItemAsDefault line_item_manufecturer', line_item_manufecturer);
        consoleLog('saveLineItemAsDefault line_item_color', line_item_color);
        consoleLog('saveLineItemAsDefault cost', line_item_cost);
        consoleLog('saveLineItemAsDefault waste', line_item_waste);
        consoleLog('saveLineItemAsDefault adjusted_quantity', line_item_adjusted_quantity);
        consoleLog('saveLineItemAsDefault apply_op', applyOPIsChecked);
        console.log('saveLineItemAsDefault lineItemName', lineItemChange);
        
        try {
            showSynching();
            let save_line_item_default = await API.graphql({
                query: saveLineItmAsDefault,
                variables: {
                    force_update: force_update,
                    line_item_id: line.line_item_id,
                    doc_line_item_id: line.doc_line_item_id,
                    doc_type_id: typeof props.url_params.doc_type_id != 'undefined' ? props.url_params.doc_type_id : allData.doc_type_id,
                    client_id: client_id,
                    team_id: team_id,
                    name: `${lineItemChange}`,
                    description: line.description,
                    manufacturer_id: line.manufacturer_id,
                    sku: line.sku,
                    line_item_code: line.line_item_code,
                    scope_type_id: line.scope_type_id,
                    cost: line_item_cost,
                    quantity: line_item_quantity,
                    adjusted_quantity: line_item_adjusted_quantity,
                    unit: line_item_unit,
                    convert_to: line.convert_to,
                    ratio_from: line.ratio_from,
                    ratio_to: line.ratio_to,
                    convert_round: line.convert_round,
                    algorithm: line.algorithm,
                    labor_cost: line_item_labor_cost,
                    material_cost: line_item_material_cost,
                    profit: line_item_profit,
                    overhead: line_item_overhead,
                    waste: line_item_waste,
                    notes: line.notes,
                    sub_line_item: line.sub_line_item,
                    product: line.product,
                    manufacturer: line.manufacturer,
                    apply_op: +applyOPIsChecked,
                    status: line.status,
                    color:line_item_color
                },
            });
            consoleLog('saveLineItemAsDefault save_line_item_default', save_line_item_default);
            save_line_item_default = JSON.parse(save_line_item_default?.data?.saveLineItmAsDefault);
            if(save_line_item_default?.insertId){
                set_line_item((prev) => ({
                    ...prev,
                    line_item_id: save_line_item_default?.insertId,
                    is_default_by_user:1
                }));
            }
            if (save_line_item_default?.statusCode == 200 && save_line_item_default?.message == "exists") {
                setShowSaveLineAsDefaultModal(true);
            }
            const category = allData.structures[structureIndex]?.categories.find((c) => c.category_id === categoryId);
            if (category) {
              const lineItem = category.line_items.find((l) => l.doc_line_item_id === line_item.doc_line_item_id);
              if (lineItem) {
                lineItem.is_default_by_user = 1;
              }
            }
            dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
            props.updateDocOnElasticSearch();
            
        } catch (err) {
            console.error(err);
        }
        setIsDisabled(false);
        setIsNameUpdated(false);
    }

    async function renameLineItem() { 
        console.log("renameLineItem", inputRef)
        if (inputRef.current) {
          setTimeout(() => {
            inputRef.current.focus();
          }, 0);
        }
    }

    async function handleLineItemBlurValue(e) {
        if (onBlurFlag) {
            if (typeof props.url_params.doc_id != 'undefined') { //check if its a document [this function will only run for documents]
                let allData = stData;
                consoleLog('handleLineItemBlurValue allData', allData);
                let line = {};
                if(line_item?.doc_line_item_id){
                    consoleLog("doc_line_item_id Update Should be case ", line_item.doc_line_item_id);
                    line = allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.doc_line_item_id == line_item.doc_line_item_id);
                }else{
                    consoleLog("doc_line_item_id add new document line item Should be case ", line_item.line_item_id);
                    line = allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id);
                }
                consoleLog('handleLineItemBlurValue line', line);
                consoleLog('handleLineItemBlurValue line.doc_id', line.doc_id);
                consoleLog('handleLineItemBlurValue props.url_params.doc_id', props.url_params.doc_id);
                if (line.doc_id == props.url_params.doc_id && typeof line.doc_line_item_id != 'undefined') { // check if LineItem is created or not [update]
                    consoleLog('handleLineItemBlurValue update');
                    consoleLog('handleLineItemBlurValue doc_line_item_id', line.doc_line_item_id);
                    consoleLog('handleLineItemBlurValue quantity', line_item_quantity);
                    consoleLog('handleLineItemBlurValue unit', line_item_unit);
                    consoleLog('handleLineItemBlurValue convert_to', line_item_convert_to);
                    consoleLog('handleLineItemBlurValue line_item_manufecturer', line_item_manufecturer);
                    consoleLog('handleLineItemBlurValue line_item_color', line_item_color);
                    consoleLog('handleLineItemBlurValue cost', line_item_cost);
                    consoleLog('handleLineItemBlurValue waste', line_item_waste);
                    consoleLog('handleLineItemBlurValue adjusted_quantity', line_item_adjusted_quantity);
                    consoleLog('handleLineItemBlurValue apply_op', applyOPIsChecked);
                    consoleLog('handleLineItemBlurValue isNameUpdated', isNameUpdated);
                    try {
                        showSynching();
                        const update_line_item = await API.graphql({
                            query: updateLineItemWorkOrder,
                            variables: {
                                name: `${lineItemChange}`,
                                doc_line_item_id: line.doc_line_item_id,
                                quantity: line_item_quantity,
                                unit: line_item_unit,
                                convert_to: line_item_convert_to,
                                algorithm: line.algorithm,
                                ratio_from: +line_item_ratio_from,
                                ratio_to: +line_item_ratio_to,
                                convert_round: line_item_convert_round,
                                manufacturer: line_item_manufecturer,
                                color: line_item_color,
                                cost: line_item_cost,
                                waste: line_item_waste,
                                adjusted_quantity: line_item_adjusted_quantity,
                                apply_op: +applyOPIsChecked,
                                remove_prices: +hideUnitPriceColumn,
                            },
                        });
                        consoleLog('handleLineItemBlurValue update_line_item', update_line_item);
                        props.updateDocOnElasticSearch();
                    } catch (err) {
                        console.error(err);
                    }
                } else { // create
                    setOnBlurFlag(false);
                    consoleLog('handleLineItemBlurValue create');
                    let doc_struct_id = allData.structures[structureIndex].doc_structure_id;
                    let doc_cat_id = allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).doc_category_id;
                    consoleLog('handleLineItemBlurValue doc_struct_id', doc_struct_id);
                    consoleLog('handleLineItemBlurValue doc_cat_id', doc_cat_id);

                    if (typeof line.color == 'undefined') {
                        line.color = '';
                    }
                    if (typeof line.remove_prices == 'undefined') {
                        line.remove_prices = 0;
                    }
                    if (typeof line.sort_order == 'undefined') {
                        line.sort_order = 0;
                    }
                    try {
                        showSynching();
                        const create_line_item = await API.graphql({
                            query: createDocLineItem,
                            variables: {
                                // doc_type_id
                                // status
                                doc_id: props.url_params.doc_id * (1),
                                doc_structure_id: doc_struct_id * (1),
                                doc_category_id: doc_cat_id * (1),
                                line_item_id: line.line_item_id, //
                                manufacturer_id: line.manufacturer_id * (1), //
                                sku: line.sku, //
                                scope_type_id: line.scope_type_id * (1), //
                                line_item_code: line.line_item_code, //
                                name: line.name,
                                description: line.description, //
                                cost: line.cost * (1), //
                                quantity: line.quantity * (1), //
                                adjusted_quantity: line.adjusted_quantity, //
                                unit: line.unit, //
                                convert_to: line.convert_to, //
                                ratio_from: line.ratio_from * (1), //
                                ratio_to: line.ratio_to * (1), //
                                convert_round: line.convert_round * (1), //
                                algorithm: line.algorithm, //
                                labor_cost: line.labor_cost * (1), //
                                material_cost: line.material_cost * (1), //
                                profit: line.profit * (1), //
                                overhead: line.overhead * (1), //
                                waste: line.waste * (1), //
                                notes: line.notes, //
                                sub_line_item: line.sub_line_item, //
                                manufacturer: line.manufacturer,//
                                apply_op: line.apply_op, //
                                product: line.product, //
                                color: line.color,
                                remove_prices: line.remove_prices,
                                sort_order: line.sort_order
                            },
                        });
                        consoleLog('handleLineItemBlurValue line.sub_line_item', typeof line.sub_line_item);
                        consoleLog('handleLineItemBlurValue create_line_item', create_line_item);
                        let new_line_item_id = JSON.parse(create_line_item.data.createDocLineItem).data.doc_line_item_id;
                        consoleLog('handleLineItemBlurValue new_line_item_id', new_line_item_id);

                        allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.doc_line_item_id == line_item.doc_line_item_id).doc_line_item_id = new_line_item_id;
                        allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.doc_line_item_id == line_item.doc_line_item_id).doc_structure_id = doc_struct_id * (1);
                        allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.doc_line_item_id == line_item.doc_line_item_id).doc_category_id = doc_cat_id * (1);
                        allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.doc_line_item_id == line_item.doc_line_item_id).doc_id = props.url_params.doc_id * (1);
                        consoleLog('handleLineItemBlurValue allData', allData);
                        dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
                        setStData(allData);
                        props.updateDocOnElasticSearch();
                    } catch (err) {
                        console.error(err);
                    }
                    setOnBlurFlag(true);
                }
            }
        }
    }

    const [showDropdown, setShowDropdown] = useState(false);
    const [customDropdown, setCustomDropdown] = useState(false);
    const [newManufacturerName, setNewManufacturerName] = useState('');

    const handleManufactureInput = (e) => {
        consoleLog(e.target.value, 'handleManufactureInput');
        setNewManufacturerName(e.target.value);
    }

    async function handleDeleteOption(e, value) {
        e.stopPropagation();
        consoleLog('handleDeleteOption id: ', value);
        if (line_item_manufecturer === value) {
            setCustomDropdown(true)
            let allData = stData;
            let line_item_filter_on = checkDocumentUniqueID();
            allData.structures[structureIndex].categories.find(c => c.category_id == categoryId).line_items.find(l => l[line_item_filter_on] == line_item[line_item_filter_on]).manufacturer = 0;
            dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
            consoleLog('handleDeleteOption allData>', allData);
            setStData(allData);
        }
        showSynching();
        const delete_user_man = await API.graphql({
            query: removeUsersManufacturers,
            variables: {
                manufacturer_id: value
            }
        });
        consoleLog('handleDeleteOption id: ', delete_user_man);
        let user_man = props.userManufacturers;
        let temp_man = user_man.filter((t) => t.value != value);
        consoleLog('handleDeleteOption user_man', user_man);
        consoleLog('handleDeleteOption temp_man', temp_man);
        props.setUserManufacturers(temp_man);
    }

    async function addNewManufacturer() {
        consoleLog('addNewManufacturer', newManufacturerName);
        let client_id = JSON.parse(secureLocalStorage.getItem("client_data")).client_id;
        let team_id = JSON.parse(secureLocalStorage.getItem("client_data")).team_id;
        if (!newManufacturerName) {
            setShowDropdown(false);
            return false;
        }
        showSynching();
        const create_user_man = await API.graphql({
            query: saveUsersManufacturers,
            variables: {
                user_id: client_id,
                team_id: team_id,
                name: newManufacturerName
            }
        });
        let create_user_man_data = create_user_man.data.saveUsersManufacturers;
        let create_man_id = JSON.parse(create_user_man_data).insertId;
        consoleLog('addNewManufacturer', create_user_man_data);
        consoleLog('addNewManufacturer', create_man_id);
        let user_man = props.userManufacturers;
        let temp_man = {};
        temp_man.value = create_man_id;
        temp_man.label = newManufacturerName;
        user_man.push(temp_man);
        consoleLog('addNewManufacturer', user_man);
        props.setUserManufacturers(user_man);
        set_line_item_manufecturer(newManufacturerName);
        setNewManufacturerName('');
        line_item.manufacturer = newManufacturerName;
        handleLineItemBlurValue(0);
        setShowDropdown(false);
    }

    function Option(props) {
        return (
            <components.Option {...props}>
                <div className='customOptions'>
                    <div>{props.children}</div>
                    {props.children != line_item_manufecturer && props.children  != 'Leave blank' &&
                        <img onClick={(e) => { handleDeleteOption(e, props.data.value) }} src={Trash} />
                    }
                </div>
            </components.Option>
        );
    }

    const [isActive, setActive] = useState(false);
    const ToggleClass = () => {
        setActive(!isActive);
    };

    const getBackgroundColor = (snapshot) => {
        consoleLog('getBackgroundColor', snapshot);
        if (snapshot.isDropAnimating) {
            return 'none';
        }
        if (snapshot.isDragging) {
            return '0 0 .5rem #000';
        }
        return 'none';
    };
    const handleKeyDown = (event) => {
        const insertSymbol = (symbol) => {
            event.preventDefault();
            console.log("Symbol inserted ", symbol);
            event.target.innerText = event.target.innerText+symbol;
            handleLINameChange(event);
        };
        const matchCase = (pressedKey) => {
            switch (pressedKey) {
                case 'g':
                case 'c':
                    insertSymbol('©');
                    break;
                case '2':
                case 't':
                    insertSymbol('™');
                    break;
                case 'r':
                    insertSymbol('®');
                    break;
                default:
                    break;
            }
        };
        // For Windows/Linux (Ctrl+Shift) Mac (Option key)
        if (event.metaKey || event.altKey) {
            matchCase(event.key.toLowerCase());
        }
    };

    return (

        <Master>
            {
                showSaveLineAsDefaultModal &&
                <SaveLineAsDefault
                    showModal={showSaveLineAsDefaultModal}
                    setShowModal={setShowSaveLineAsDefaultModal}
                    overwrite={() => saveLineItemAsDefault(true)}
                    rename={renameLineItem}
                />
            }
            <Row className="XS m-0 pt-2 pb-2" style={{ padding: '10px 0' }}>
                <Col sm={12} xs={12} md={2} lg={2} xl={2} className="text-left pl-5">
                    <div className="showXS">
                        <h5>ITEM NAME</h5>
                    </div>
                    <div className=" h-100 d-flex align-items-center ip-input"
                        contentEditable={'true'}
                        spellCheck={'false'}
                        onInput={handleLINameChange}
                        onBlur={handleLineItemBlurValue}
                        onKeyDown={handleKeyDown}
                        disabled={isDisabled}
                        ref={inputRef}
                    >
                        {
                            lineItemName
                        }
                    </div>

                </Col>
                <Col sm={12} xs={12} md={1} lg={1} xl={1} className="text-left  ">
                    <div className="showXS ">
                        <h5>QUANTITY</h5>
                    </div>
                    {line_item_unit != '' &&
                        <input
                            type="text"
                            name="quantity"
                            className="ip2 input-border"
                            placeholder="0"
                            autoComplete='off'
                            value={line_item_adjusted_quantity > 0 ? line_item_adjusted_quantity : ''}
                            onChange={handleLIAdjQuantityChangeValue}
                            onBlur={handleLineItemBlurValue}
                            disabled={isDisabled}
                        />
                    }
                </Col>
                <Col sm={12} xs={12} md={1} lg={1} xl={1} className="text-left select_unit">
                    <div className="showXS">
                        <h5>UNIT</h5>
                    </div>
                    <Select
                        value={UNITS.find(obj => obj.value === line_item_unit)} // set selected value
                        options={UNITS} // set list of the UNIT
                        onChange={handleLIConvertToChange}
                        onBlur={handleLineItemBlurValue}
                        components={{ DropdownIndicator }}
                        className="w-100 height"
                        classNamePrefix="react-select"
                        disabled={isDisabled}
                        defaultValue=""
                        styles={{
                            placeholder: (base) => ({
                                ...base,
                                fontSize: "1em",
                                fontFamily: "Roboto",
                                color: "#B4BEBF",
                            }),
                            control: (provided, state) => ({
                                ...provided,

                                boxShadow: "none",
                                border: state.isFocused ? '1px solid #f7941c' : '1px solid #B4BEBF',
                                overflowX: "hidden",
                                fontFamily: "Roboto"
                            }),
                            menu: (provided, state) => ({
                                ...provided,
                                border: state.isFocused && "1px solid #B4BEBF",
                                border: "1px solid #B4BEBF",
                                overflowX: "hidden",
                                fontFamily: "Roboto"
                            }),
                            option: (provided, state) => ({
                                ...provided,

                                overflowX: "hidden",
                                backgroundColor: state.isFocused && "#F7F9FA",
                                color: state.isFocused && "#212E33",
                                borderBottom: "1px solid #B4BEBF",
                                fontFamily: "Roboto"
                            }),
                        }}
                    />
                </Col>

                <Col sm={12} xs={12} md={2} lg={2} xl={2} className="text-left custom-sm-space-20 d-flex p-lg-0 p-md-0">
                    <div className="showXS">
                        <h5>Manufacturer</h5>
                    </div>
                    {/* <input type="number" className=" ip2" placeholder="(none)" style={{position:'relative'}} /> */}
                    {
                        showDropdown == false ? (
                            <Select
                                value={!customDropdown && props.userManufacturers.find(obj => obj.label == line_item_manufecturer)} // set selected value
                                options={[{ value: '', label: 'Leave blank' }, ...props.userManufacturers]}
                                onChange={handleLIManufecturerChangeValue}
                                onBlur={handleLineItemBlurValue}
                                components={{ DropdownIndicator, Option }}
                                className="w-100 height"
                                defaultValue=""
                                styles={{
                                    placeholder: (base) => ({
                                        ...base,
                                        fontSize: "1em",
                                        fontFamily: "Roboto",
                                        color: "#B4BEBF",
                                    }),
                                    control: (provided, state) => ({
                                        ...provided,

                                        boxShadow: "none",
                                        border: state.isFocused ? '1px solid #f7941c' : '1px solid #B4BEBF',
                                        overflowX: "hidden",
                                        fontFamily: "Roboto",
                                    }),
                                    menu: (provided, state) => ({
                                        ...provided,
                                        border: state.isFocused && "1px solid #B4BEBF",
                                        border: "1px solid #B4BEBF",
                                        overflowX: "hidden",
                                        fontFamily: "Roboto",
                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        overflowX: "hidden",
                                        backgroundColor: state.isFocused && "#F7F9FA",
                                        color: state.isFocused && "#212E33",
                                        borderBottom: "1px solid #B4BEBF",
                                        fontFamily: "Roboto",
                                    }),

                                }}
                            />) : (
                            <input className='manufacture-input w-100 height input-border' onChange={handleManufactureInput} />
                        )
                    }{
                        showDropdown == false ?
                            <div className='edit-icon' onClick={() => setShowDropdown(true)} >
                                <img src={Edit} />
                            </div>
                            :
                            <div className='tick-icon' onClick={addNewManufacturer}>
                                <img src={Tick} />
                            </div>
                    }
                    {/* <img src={EditI} style={{width:'16px', height:'auto', position:'absolute', right:'25px', top:'10px'}} alt=""/> */}
                </Col>
                <Col sm={11} xs={11} md={2} lg={2} xl={2} className="text-left spacing-block ">
                    <div className="showXS">
                        <h5>Color</h5>
                    </div>
                    <div
                        style={{ position: "relative" }}
                        className="d-flex align-items-center"
                    >
                        <input type="text" className=" ip2 pl-4 input-border" onChange={handleLIColorPicker} style={{ position: 'relative' }} placeholder='Color' value={line_item_color} onBlur={handleLineItemBlurValue}   disabled={isDisabled}/>
                    </div>
                    {/* <img src={EditI} style={{width:'16px', height:'auto', position:'absolute', right:'25px', top:'10px'}} alt=""/> */}
                </Col>
                <Col sm={11} xs={11} md={1} lg={1} xl={1} className="text-left spacing-block">
                    <div className="showXS">
                        <h5>UNIT PRICE</h5>
                    </div>
                    {line_item_unit != '' &&
                        <div
                            style={{ position: "relative", fontFamily: "Roboto" }}
                            className="d-flex align-items-center"
                        >
                            <input
                                type="text"
                                className=" ip2 pl-4 input-border"
                                onChange={handleLICostChangeValue}
                                value={parseFloat(line_item_cost) ? line_item_cost : null}
                                placeholder="0.00"
                                onBlur={handleLineItemBlurValue}
                                disabled={isDisabled}
                            />
                            <span
                                style={{ position: "absolute", left: "4px", fontSize: "14px" }}
                            >
                                $
                            </span>
                        </div>
                    }
                </Col>
                <Col sm={11} xs={11} md={1} lg={1} xl={1} className="text-left  px-1">
                    <div className="showXS">
                        <h5>NET COST</h5>
                    </div>
                    {line_item_unit != '' &&
                        <div
                            style={{ position: "relative", width: '135px', fontFamily: "Roboto" }}
                            className="d-flex align-items-center"
                        >
                            <input
                                type="number"
                                className="ip22 border-none input-border"
                                value={line_item_net_cost}
                                placeholder="$0.00"
                                disabled={true}
                            />
                            <span
                                style={{ position: "absolute", left: "0px", fontSize: "14px" }}
                            >
                                $
                            </span>
                        </div>
                    }
                </Col>
                <Col sm={12} xs={12} md={1} lg={1} xl={1} className="text-center ">
                    <div className="showXS">
                        <h5></h5>
                    </div>
                    {/* <button
                        className="custombtnn h-100 d-flex align-items-center"
                        onClick={() => addNoteFunc()}
                        style={{marginLeft:'10px'}}
                    >
                        Note
                    </button> */}
                    {!isDisabled ? (
                    <DropdownButton className={isActive ? "box-m-btn-active" : "box-m-btn"} title='...' onClick={() => setActive(!isActive)} onBlur={() => setActive(false)}>
                        <div>
                            <Dropdown.Item className="custom-li-font w-100" onClick={() => addNoteFunc()}>

                                <span className="link-text">Add Sub-line Note</span>

                            </Dropdown.Item>
                            <Dropdown.Item className="custom-li-font w-100" onClick={() => deleteLineItm(props.line_item.doc_line_item_id)}><span className="link-text">Delete Line</span></Dropdown.Item>
                            <Dropdown.Item className="custom-li-font w-100" onClick={() => 
                            {
                                (props.line_item?.is_default_by_user == 1 && !isNameUpdated)?
                                setShowSaveLineAsDefaultModal(true):
                                saveLineItemAsDefault(false)
                            }}
                            ><span className="link-text">Save Line as Default?</span></Dropdown.Item>

                        </div>
                    </DropdownButton>
                    ) : (
                        <FaCircleNotch size={22} className="awesome_spinner" style={{ color: "#414141" }} />
                    )}
                </Col>
                <Col sm={12} xs={12} md={1} lg={1} xl={1} className="text-center ">
                    <div className="showXS">
                        <h5></h5>
                    </div>
                    <button
                        className="custombtnn h-100 d-flex align-items-center"
                        onClick={() => toggleEditLineItem(!editLineItem)}
                        style={{ marginLeft: '10px', fontFamily: 'Rajdhani', color: '#F7941C', fontSize: '14px', fontWeight: 'bold' }}
                    >
                        {editLineItem ? "CLOSE" : "EDIT"}
                    </button>
                </Col>
            </Row>


            {editLineItem &&

                <NestedAccordian className="pl-5 pr-5 pt-2 pt-2">
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={9} className="edit_line_item fs-smaller">
                            <Row className=" py-5">
                                <Col lg={4} md={4} sm={12} >
                                    <p className="m-0 fs-12px mb-2">Waste Factor</p>
                                    <div className="d-flex align-items-center">
                                        <input
                                            type="text"
                                            className="ip mr-1 w-100 input-border"
                                            placeholder='0.00'
                                            style={{ position: "relative" }}
                                            value={line_item_waste > 0 ? line_item_waste : ''}
                                            onBlur={handleLineItemBlurValue}
                                            onChange={handleLIWasteFactorChangeValue}
                                        />
                                        <span
                                            style={{
                                                position: "absolute",
                                                right: "10%",
                                                fontSize: "14px",
                                                fontFamily: "Roboto",
                                            }}
                                        >
                                            %
                                        </span>
                                    </div>
                                </Col>
                                <Col lg={4} md={4} sm={12}>
                                    <p className="m-0 fs-12px mb-2">QTY</p>
                                    <div className="d-flex align-items-center">
                                        <input
                                            type="text"
                                            className="ip mr-1 w-100 input-border"
                                            placeholder='0.0'
                                            style={{ position: "relative" }}
                                            onBlur={handleLineItemBlurValue}
                                            value={line_item_quantity > 0 ? line_item_quantity : ''}
                                            onChange={handleLIQuantityChangeValue}
                                        />
                                        <span
                                            style={{
                                                position: "absolute",
                                                right: "9%",
                                                fontSize: "14px",
                                                fontFamily: "Roboto",
                                            }}
                                        >
                                            {line_item_algo_unit}
                                        </span>
                                    </div>
                                </Col>
                                <Col lg={4} md={4} sm={12}>
                                    <p className="m-0 fs-12pxs mb-2">Conversion</p>
                                    <div className="d-flex align-items-center" style={{ fontFamily: "Roboto" }}>
                                        <input type="text" className="ip mr-1 w-50 input-border" value={line_item_algo_unit} disabled />
                                        <p className="w-15 mb-0 fs-12px text-center">to: </p>

                                        <Select
                                            value={defaultUnits.find(obj => obj.name === line_item_convert_to)} // set selected value
                                            options={defaultUnits} // set list of the UNITS
                                            onChange={handleLIConvertToChangeTo} // assign onChange function
                                            onBlur={handleLineItemBlurValue}
                                            className="w-60 height"
                                            classNamePrefix="react-select"
                                            components={{ DropdownIndicator }}
                                            defaultValue=""
                                            styles={{
                                                placeholder: (base) => ({
                                                    ...base,
                                                    fontFamily: "Roboto",
                                                    color: "#B4BEBF",
                                                }),
                                                control: (provided, state) => ({
                                                    ...provided,

                                                    fontSize: 14,
                                                    boxShadow: "none",
                                                    border: state.isFocused ? '1px solid #f7941c' : '1px solid #B4BEBF',
                                                    overflowX: "hidden",
                                                    fontFamily: "Roboto",
                                                }),
                                                menu: (provided, state) => ({
                                                    ...provided,
                                                    border: state.isFocused && "1px solid #B4BEBF",
                                                    border: "1px solid #B4BEBF",
                                                    overflowX: "hidden",
                                                    fontFamily: "Roboto",
                                                }),
                                                option: (provided, state) => ({
                                                    ...provided,

                                                    overflowX: "hidden",
                                                    backgroundColor: state.isFocused && "#F7F9FA",
                                                    color: state.isFocused && "#212E33",
                                                    borderBottom: "1px solid #B4BEBF",
                                                    fontFamily: "Roboto",
                                                }),
                                            }}
                                        />
                                    </div>
                                </Col>

                            </Row>
                            <Row style={{ minHeight: '80px' }} className={`d-flex align-items-center  ${roundToggle && 'justify-content-between'}`}>
                                {line_item_convert_to != '' &&
                                    <Col lg={6} md={6} sm={12} className="mt-5">
                                        <div className="d-flex align-items-center mt-4 pt-3">
                                            <input type="text" className="ip mr-1 w-100 input-border" value={line_item_ratio_from > 0 ? line_item_ratio_from : ''} onChange={handleLIRatioFromChange} onBlur={handleLineItemBlurValue} />
                                            <span
                                                style={{
                                                    position: "absolute",
                                                    right: "56%",
                                                    fontSize: "14px",
                                                }}
                                                className="m-0 fs-12px"> {line_item_algo_unit} </span>

                                            <p className="m-0 fs-12px mx-2 d-flex" > <span>=</span>  </p>

                                            <input type="text" className="ip mr-1 w-100 input-border" style={{ position: "relative" }} value={line_item_ratio_to} onChange={handleLIRatioToChange} onBlur={handleLineItemBlurValue} />
                                            <span
                                                style={{
                                                    position: "absolute",
                                                    right: "8%",
                                                    fontSize: "14px",
                                                }}
                                                className="m-0 fs-12px"> {line_item_convert_to ?? 'CU'} </span>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-start  mt-2">
                                            <input type="checkbox" className="mr-1" defaultChecked={line_item_convert_round} onChange={handleLINearestCUChangeValue} onBlur={handleLineItemBlurValue} />
                                            <p className="fs-12px mb-0 pt-2">Round up to the nearest whole converted unit</p>
                                        </div>
                                    </Col>
                                }
                                <Col lg={6} md={6} sm={12} >

                                    <p className="m-0 fs-12pxs px-1 mb-2">Adjusted QTY</p>
                                    <div className="d-flex align-items-center" style={{ fontFamily: "Roboto" }}>
                                        <input type="number" className="ip mr-1 w-75 input-border" style={{ position: "relative" }} value={parseFloat(line_item_adjusted_quantity).toFixed(2)} disabled />
                                        <span
                                            style={{
                                                position: "absolute",
                                                right: "29%",
                                                fontSize: "14px",
                                            }}
                                            className="fs-12pxs">{line_item_convert_to}</span>
                                    </div>


                                </Col>
                            </Row>
                            <>
                                <Row className="pt-5 w-100 my-5 dd-row">
                                    <Col xs={12} md={6}>
                                        <p style={{ fontSize: '14px', color: '#212E33', fontFamily: 'Roboto', fontWeight: "500" }}>Calculate QTY From: <b>{algoValue}</b></p>
                                        {/* <p><b>Inside Corners (0 EA)</b></p> */}
                                        <Select
                                            value={selectedReportType} // Dynamic selected value
                                            options={reportType}
                                            onChange={handleLReportTypeChangeValue}
                                            components={{ DropdownIndicator }}
                                            className="custom-input-y w-100 border-none height"
                                            classNamePrefix="react-select"
                                            styles={{
                                                placeholder: (base) => ({
                                                    ...base,
                                                    fontSize: "14px",
                                                    fontFamily: "Roboto",
                                                    color: "#B4BEBF",
                                                }),
                                                control: (provided, state) => ({
                                                    ...provided,

                                                    fontSize: "14px",
                                                    fontFamily: "Roboto",
                                                    boxShadow: "none",
                                                    border: state.isFocused ? '1px solid #f7941c' : '1px solid #B4BEBF',
                                                    overflowX: "hidden",
                                                }),
                                                menu: (provided, state) => ({
                                                    ...provided,
                                                    border: state.isFocused && "1px solid #B4BEBF",
                                                    border: "1px solid #B4BEBF",
                                                    overflowX: "hidden",
                                                }),
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: "14px",
                                                    fontFamily: "Roboto",
                                                    overflowX: "hidden",
                                                    backgroundColor: state.isFocused && "#F7F9FA",
                                                    color: state.isFocused && "#212E33",
                                                    borderBottom: "1px solid #B4BEBF",
                                                }),
                                            }}
                                        />
                                    </Col>
                                    <Col xs={12} md={6} className="text-left custom-section">
                                        {/* <button
                                                className="custombtnn"
                                                onClick={() => toggleShowReport(!showReport)}
                                            >
                                                Change Calculation
                                            </button> */}
                                        <p style={{ fontSize: '14px', color: '#212E33', fontFamily: 'Roboto' }}></p>
                                        <Select
                                            value={selectedReportUnit} // Dynamic selected value for the second select
                                            options={reportUnits}
                                            onChange={handleChangeScopeUnit}
                                            onBlur={handleLineItemBlurValue}
                                            components={{ DropdownIndicator }}
                                            className="custom-input-yy border-none height"
                                            classNamePrefix="react-select"
                                            styles={{
                                                placeholder: (base) => ({
                                                    ...base,
                                                    fontSize: "14px",
                                                    fontFamily: "Roboto",
                                                    color: "#B4BEBF",
                                                }),
                                                control: (provided, state) => ({
                                                    ...provided,

                                                    fontSize: "14px",
                                                    fontFamily: "Roboto",
                                                    boxShadow: "none",
                                                    border: state.isFocused ? '1px solid #f7941c' : '1px solid #B4BEBF',
                                                    overflowX: "auto",
                                                }),
                                                menu: (provided, state) => ({
                                                    ...provided,

                                                    border: state.isFocused && "1px solid #B4BEBF",
                                                    border: "1px solid #B4BEBF",
                                                    overflowX: "auto",
                                                }),
                                                option: (provided, state) => ({
                                                    ...provided,

                                                    fontSize: "14px",
                                                    fontFamily: "Roboto",
                                                    overflowX: "auto",
                                                    backgroundColor: state.isFocused && "#F7F9FA",
                                                    color: state.isFocused && "#212E33",
                                                    borderBottom: "1px solid #B4BEBF",
                                                }),
                                            }}
                                            disabled={ddLoader}
                                        />
                                    </Col>
                                    {ddLoader &&
                                        <div className='loader-section'>
                                            <img className='dd-loader' src={DDLoader} alt="" width="35px" height="35px" />
                                        </div>
                                    }
                                </Row>

                                {/* 
                                {showReport &&
                                    <Row className="w-100">
                                        <Col xs={12} md={12}>
                                            <div className="ChooseReport">
                                                <div className="header_Report">
                                                    <p className="title m-0 ">Choose a Report Type Below</p>
                                                </div>
                                                <div className="body_Report">
                                                    <div onClick={() => toggleShowCheckBox(!showCheckBox)} className="title">RoofScope</div>
                                                </div>
                                                {showCheckBox &&
                                                    <div className="body_Report">
                                                        <div className="checkbox">
                                                            <input
                                                                type="checkbox"
                                                                id=""
                                                                name=""
                                                                value="Hi"
                                                                checked={isChecked}
                                                                onChange={handleOnChangeCheckBox}
                                                            />
                                                            Hi
                                                        </div>
                                                        <div className="checkbox">
                                                            <input
                                                                type="checkbox"
                                                                id=""
                                                                name=""
                                                                value="Hi"
                                                                checked={isChecked}
                                                                onChange={handleOnChangeCheckBox}
                                                            />
                                                            Hi
                                                        </div>
                                                        <div className="checkbox">
                                                            <input
                                                                type="checkbox"
                                                                id=""
                                                                name=""
                                                                value="Hi"
                                                                checked={isChecked}
                                                                onChange={handleOnChangeCheckBox}
                                                            />
                                                            Hi
                                                        </div>
                                                    </div>
                                                }

                                            </div>
                                        </Col>
                                    </Row>
                                } */}
                            </>
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={3}>
                            {/* <button className="col-btn text-left" onClick={() => handleRemoveLineItem(props.line_item.line_item_id)}> 
                                <i><span><FaTrashAlt /></span> REMOVE LINE ITEM</i>
                            </button>
                            <button className="col-btn text-left" style={{ background: "black" }} onClick={addNoteFunc}> 
                                <i><span><FaPlus /></span> ADD SUBLINE NOTES</i>
                            </button>
                            <p className="pt-4">
                                <Link className="my-2 text-red text-underline">SAVE AS LINE DEFAULT</Link>
                            </p> */}
                            <div className="right-side-check my-5">
                            </div>
                        </Col>

                    </Row>
                </NestedAccordian>

            }

            <div className="my-3 w-100">
                <DragDropContext onDragEnd={handleOnDragEndStructureNotes}>
                    <Droppable droppableId="droppable-1">
                        {(provided, _) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                {(Array.isArray(sub_line_item)) &&
                                    sub_line_item.map((note, i) => (
                                        <Draggable key={Math.random()} draggableId={"draggable-" + i} index={i}>
                                            {(provided, snapshot) => (
                                                <div
                                                    className='spacing-block'
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    style={{
                                                        ...provided.draggableProps.style,
                                                        boxShadow: getBackgroundColor(snapshot),
                                                        paddingLeft: "5em",
                                                    }}
                                                >
                                                    <Handle {...provided.dragHandleProps}>
                                                        <img src={Move} alt="" style={{ paddingLeft: '13px' }} />
                                                    </Handle>
                                                    <Notes
                                                        note={note}
                                                        line_item_id={props.line_item.line_item_id}
                                                        onChange={handleSubLineItemChange}
                                                        count={i}
                                                        onAddNote={handleAddNote}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    )
                                    )}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
            {synching && <img className="sync" src={Sync} />}
        </Master>
    )
}

const Handle = styled.div`
  position: absolute;
  left: 0px;
  margin: 17px 5.5em;
  cursor: grab;
  @media (max-width: 768px) {
    margin: 17px 0.5em;
  }
`;

const customStyles = {
    control: base => ({
        ...base,
        height: 30,
        minHeight: 30
    })
};



const Master = styled.div`
    width: 100%;
    min-height: 61px;
    padding-top: 10px;
    border-bottom: 2px solid #f7f9fa;
    background: #fff;

.custom-margin-sm{
    margin-top:-25px;


}

.customOptions {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.edit-icon {
    width: 38px;
    height: 38px;
    padding: 10px;
    display: flex;
    border: 1px solid #b4bebf;
    border-radius: 4px;
    margin-left: 5px;

    @media(max-width: 767px) {
        margin-top: 10px;
        margin-left: 0;

    }

    img {
        &:hover {
            opacity: 0.5;
        }
    }
}

.tick-icon {
    width: 38px;
    height: 38px;
    padding: 10px;
    display: flex;
    border: 1px solid #b4bebf;
    border-radius: 4px;
    margin-left: 5px;

    img {
        &:hover {
            opacity: 0.5;
        }
    }
}

.manufacture-input {
    height: 38px;
    padding: 2px 8px;
    border: 1px solid #b4bebf;
    border-radius: 4px;

    &:focus-visible {
        outline: none;
    }
}

.height .react-select__control {
    height: 40px;
}

.react-select__option {
    &:last-child {
        border-bottom: none;
    }
}

.spacing-block {
    @media(max-width: 767px) {
        padding-left: 0;
    }
}
.ip-input{
    width:100%;
    outline:none;
    border-radius:4px;
    border:none !important;
    color:#212E33;
    font-family: "Roboto";
    font-style: normal;
    font-size:14px;
    word-break: break-all;
}
.ip-input:focus {
    outline: none !important;
    border: 1px solid #f7941c !important;
    padding-left:10px;
    padding-right:10px;
    padding-top:5px;
    padding-bottom:5px;
}
.dropdown-menu {
    inset: unset !important;
    background: white !important;
    min-width: 180px !important;
    padding: 0;
    transform: translate(-200px, -47px) !important;
  }
  .dropdown-item {
    width: 180px !important;
    padding: 0;
  }
  .dropdown-toggle:focus{
    background: #ebebeb !important;
  }
  .dropdown-item:hover {
    background: #ebebeb !important;
  }
  .dropdown-menu:before {
    display: none !important;
  }
  .dropdown-menu:after {
    display: none !important;
  }
  .dropdown-menu.show{
    left:0px !important;
  }
.box-m-btn {
  height: 36px;
  width: 36px;
  border-radius: 3px;
  .dropdown-toggle {
    display: flex;
    justify-content: center;
    font-weight: 600;
    font-size: 18px;
    color: black;
    border: none;
    box-shadow: unset;
    background-color: #d6e1e5 !important;
    margin : 0;
    line-height: 1;
    height: 36px;
    width: 36px;
  }
}
.custom-li-font {
  text-decoration: none;
  font-family: Roboto;
  color: #737f82 !important;
}
.link-text {
    margin: 15px;
    line-height: 2.6;
  }
.box-m-btn-active {
  height: 36px;
  width: 36px;
  border-radius: 3px;
  .dropdown-toggle {
    display: flex;
    justify-content: center;
    font-weight: 600;
    font-size: 18px;
    color: black;
    border: none;
    box-shadow: unset;
    background-color: #f7941c !important;
    margin : 0;
    line-height: 1;
    height: 36px;
    width: 36px;
  }
}

  .custom-sm-space-20{

      @media(max-width:600px){
          padding: 0 15px;
          flex-direction: column;
      }
  }

  .szh-menu{
    left: -142.45312px !important;
    top: -36px !important;

    @media(max-width:600px){
        left:60px !important;
    }
}
  
  .szh-menu__item:active{
    background:#F7941C !important;
}
    .css-mi0zgc-control{
        height:40px;
    }
    .css-h4172c-control{
        height:40px;
    }

    .css-5b9gaa-menu {
        div:last-child {
           border-bottom: none;
        }
      }
    .ext-font{
        font-size:12px;
        outline:none;
        color:#212E33;
        font-family:Roboto;
    }
    .ip2 {
        height: 40px;
        width: 100%;
        outline: none;
        font-style: normal;
        padding: 10px;
        border-radius: 4px;
        border: 1px solid #b4bebf;
        color: #212e33;
        @media (max-width: 600px) {
          width: 100%;
        }
    }
    .ip22{
        height:40px;
        width:90%;
        outline:none;
        padding:10px;
        font-style: normal;
        border-radius:4px;
        border:1px solid #fff;
        color:#212E33;
        @media(max-width:600px){
            width:100%;
        }
    }

    .custom-section {
        display: flex;
        align-items: end;
        @media(max-width: 1023px){
            width: 100% !important;
        }
    }
    .custom-input-yy{
        width:308px;
        margin-top:32px;
        outline:none ;
        border-radius:4px ;
        margin-bottom:0;
        
        @media(max-width: 1023px){
            margin-bottom: 0px !important;
            margin-top: 0px !important;
            height: auto;
        }
        @media(max-width:767px){
            margin-top: 10px !important;
            width:100%;
        }
    }
    .w-90{
        width: 90% !important;
    }

    .w-70{
        width: 70% !important;
    }

    .w-60{
        width: 60% !important;
    }

    .w-30{
        width: 30% !important;
    }

    .w-25{
        width: 25% !important;
    }

    .w-20{
        width: 20% !important;
    }

    .w-15{
        width: 15% !important;
    }

    .w-12{
        width: 12% !important;
    }

    .w-10{
        width: 10% !important;
    }

    .w-7{
        width: 7% !important;
    }

    .pt-2rem{
        padding-top: 2rem !important;
    }

    .fs-small{
        font-size: small;
    }

    .fs-smaller{
        font-size: smaller;
    }
    .fs-12px{
        font-size: 14px;
        font-family:Roboto;
        color:#212E33;
    }
    .fs-12pxs{
        font-size: 14px;
        font-family:Roboto;
        color:#F7941C;
    }
    /* .css-tlfecz-indicatorContainer{
        padding: 0 !important;
    }
    .css-1gtu0rj-indicatorContainer{
        padding: 0 !important;
    }

    .css-1hb7zxy-IndicatorsContainer{
        cursor: pointer;
        background: #414141;
        color: #ffffff;
        height:28px;
        padding: 0px;
    }
    .css-yk16xz-control{
        border-color: #414141;
    }
    .css-yk16xz-control:hover{
        border-color: #414141;
    } */
    .css-mi0zgc-control{
        height:40px !important;
        width:100% !important;
        outline:none !important;
        font-style:normal !important;
    }
    .css-h4172c-control{
        height:40px !important;
        width:100% !important;
        outline:none !important;
        font-style:normal !important;
    }

    .text-red{
        color: red !important;
    }

    .text-underline{
        text-decoration: underline !important;
    }

    /* .css-1okebmr-indicatorSeparator{
        background-color: #414141;
    }

    .css-g1d714-ValueContainer:focus {
        padding: 0px 0px 0px 7% !important;
    }

    .css-g1d714-ValueContainer{
        padding: 6px !important;
    } */

    .br-1-grey{
        border-right: 1px solid lightgrey;
    }
    
    .XS{
    
        @media(max-width:600px){
            display:flex;
            align-items:center;
            justify-content:center;
            flex-direction:column;
        }
    }
    .showXS{
        display:none;
        @media(max-width:600px){
            display:block;
        }
    }

    .spacing-block {
        @media(max-width: 767px) {
            padding-inline: 0 !important;
        }
    }

    .subline_note{
        padding-left: 5em;
        padding-bottom: 0.3em;
        .custombtnn {
            &:hover  {
              color: #f7941c;
            }
          }
    }
`
const NestedAccordian = styled.div`
    width:100%;
    align-items:flex-start;
    justify-content:center;
    flex-direction:column;
    background: #f5f5f5;
    .right-side-check{
        width:100%;
        height:100%;
        display:flex;
        align-items:flex-start;
        justify-content:flex-start;
        flex-direction:column;
        padding:10px 10px;

        @media(max-width: 1023px){
            padding-block: 0 !important;
            margin-top: 0 !important;
        }

        .content-w{
            width:100%;
            display:flex;
            align-items:center;
            justify-content:flex-start;
            margin:5px 0;


            p{
                font-size:14px;
                color:#737F82;
                font-family:Roboto;

                margin:5px 0 0 5px;
            }
        }
    }
    .edit_line_item{
        display: table-row;
    }
    
    .wd-20{
        width: 20% !important;
    }
    .ip{
        height: 40px;
        width: 90%;
        outline: none;
        padding: 10px;
        font-style: normal;
        border-radius: 4px;
        border: 1px solid #b4bebf;
        color: #212e33;
        @media (max-width: 600px) {
        width: 100%;
        }
    }

    .dd-row{
        position:relative;
        display: flex;

        @media(max-width: 767px) {
            margin-bottom: 0 !important;
                    }

    .loader-section {
        @media(max-width: 1023px){
            width: 100% !important;
            display: flex !important;
            justify-content: center;
            margin-top: 15px;
        }
    }

    .dd-loader{
        @media(min-width: 1024px) {
            position:@media(max-width: 767px) {
                margin-bottom: 0 !important;
                        }
    }

    .customCol{
        border:1px solid #c2c2c2;
        text-align:center;
        font-weight:600;
        font-size:16px;
        padding:5px;
        text-decoration:initial;
    }

    .custombtnn{
        background:transparent;
        border:none;
        outline:none;
        text-decoration:underline;
        cursor: pointer;

        @media(max-width: 767px) {
            margin-left: 0 !important;
        }
    }
    
    .customBorder{
        border-right:1px solid #c2c2c2;
    }
    .col-btn{
        height:35px;
        width:100%;
        background-color:red;
        color:#ffffff;
        border-radius:5px;
        outline:none;
        border:none;
        margin:5px 0;
        font-size: smaller;
        font-weight: 600;
    }

    .ChooseReport{
        min-height:300px;
        width:100%;
        border:1px solid black;

        .header_Report{
            background-color:gray;
            padding:5px;
        .title{
            font-size:22px;
            color:#ffffff;
            text-align:center;
            text-transform:uppercase;
            }
        }
    
        .body_Report{
            overflow-y:scroll;
            text-align:left;
            color:red;
            margin:5px 0;
            padding:10px;

            .title{
                border-bottom:1px solid #c2c2c2;
            }

            .checkbox{
                width:100%;
                padding:10px;
                margin:5px;
                font-size:22px;
                color:black;
                display:flex;
                align-items:center;
                justify-content:flex-start
            }
        }
    }

    .catBox_child{
        width: 98.5%;
        border:1px solid black;
        border-radius:5px;
        padding: 5px 0px 0px;
        button{
            border-bottom:1px solid #c2c2c2;
        }
    }
`