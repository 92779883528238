import React, { useEffect, useState, useRef } from "react";
import NotesIcon from "../../assets/Notes_Icon.svg";
import CameraLogo from '../../assets/dollar-bill.png';
import {calculateFinanceAmount} from '../CommonComponents/helper';
export default function EstimateFooter(props){
    return <>
        {props.invoice != 1 &&
            props.pageCount == props.totalPages && props.financingOptions.some(option => option.is_active === 'Y' && option.months>0 && option.percentage>0) &&
            <div className="financial_report_container" style={{ backgroundColor: "#d6e1e5", padding: "25px 50px 25px 50px", marginBlock: "9px", marginBottom: "0" }}>
            <div className="d-flex justify-content-between align-items-center">
                <div style={{ display: "flex", alignItems: "center", width: "50%", marginBottom: "5px" }}>
                <div className="bg-orange" style={{ backgroundColor: "#f79425", borderRadius: "50%", padding: "5px", marginRight: "10px" }}>
                    <img src={CameraLogo} alt="Cameralogo" />
                </div>
                <p className="mb-0" style={{ fontSize: "18px", fontWeight: "bold", fontFamily: "Rajdhani" }}>FINANCING OPTIONS*</p>
                </div>
                <div className="d-flex justify-content-end">
                </div>
            </div>
            <div className="d-flex justify-content-start align-items-center mt-2">
                {props.financingOptions.map((option, i) => (
                <>
                    {option.is_active == 'Y' && option.months>0 && option.percentage>0?
                    <div className="bg-white" style={{ marginLeft: i === 0 ? "0px" : "20px", fontFamily: "Rajdhani", backgroundColor: "white", width: "32%", padding: "15px", borderRadius: "10px" }}>
                        <p className="mb-0" style={{ fontSize: "18px", fontFamily: "Rajdhani", fontWeight: "bold" }}>{(option.months)?option.months:0} Months</p>
                        <p className="mb-0 col-cyan" style={{ display: 'flex', alignItems: "center", fontFamily: "Rajdhani", fontWeight: "bold", color: '#5ce1bb', fontSize: "22px" }}>{(option.percentage)?option.percentage:0}% =
                        {(() => {
                                // Check if option.percentage is a valid number
                                console.log("Every option when printing ===>", option);
                                const percentage = parseFloat(option.percentage);
                                let calculatedAmountText = "No Payment No Interest";
                                let fontSize = "11px";
                                if ((!isNaN(percentage) || !option.percentage == null || percentage != 0) && props.total>0 && (option.months && option.months>0)) {
                                    calculatedAmountText = calculateFinanceAmount(percentage, props.total, option.months);
                                    fontSize = "22px";
                                }
                                return (
                                    <span className="col-orange"
                                    style={{
                                        marginLeft: '5px',
                                        fontFamily: "Rajdhani",
                                        fontWeight: "bold",
                                        color: "#f79425",
                                        fontSize: fontSize,
                                    }}
                                    >
                                    {!isNaN(calculatedAmountText) ? `$${calculatedAmountText}` : calculatedAmountText}
                                    </span>
                                );
                            })()
                        }
                        </p>
                    </div>:
                    <></>
                    }
                </>
                ))}
            </div>
            <div className="d-flex justify-content-between mt-4">
                <div className="bg-orange" style={{ width: "32%", backgroundColor: "#f79425", borderRadius: "10px", padding: "10px", fontFamily: "Rajdhani", fontWeight: "bold", display: "flex", justifyContent: "center", alignItems: "center", height: "fit-content" }}>
                <p className="mb-0" style={{ fontSize: "18px", fontFamily: "Rajdhani", fontWeight: "bold" }}>{"GET APPROVED NOW >"}</p>
                </div>
                <div className="mb-0" style={{ width: "66%", fontSize: '8px', fontFamily: "Rajdhani", fontWeight: "bold" }}>
                <p style={{ fontFamily: "Rajdhani", fontWeight: "bold" }}>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At
                    vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
                    amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et</p>
                </div>
            </div>
            </div>
        }
    </>;
}