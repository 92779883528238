import React from 'react'
import Login from '../Components/Login/Login'
import { Helmet} from 'react-helmet'
import * as queryString from "query-string";

export default function proxy(props) {
    const url_params = queryString.parse(props.location.search);
    console.log('proxyToken login js url_params', url_params);
    return (
        <div>
             <Helmet>
                <meta charSet="utf-8" />
                <title>Prodocs | Login</title>
                <link rel="canonical" href="/prodocs-logo.png" />
            </Helmet>
            <Login  url_params={url_params}/>
        </div>
    )
}

