import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import "../main.css";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import styled from 'styled-components';
import { createProductAndManufacturerES, getProductAndManufacturerId, getDocumentWorkOrderData, elasticSearchFetchDoc, getOderScopeLoc, elasticSearchUpdateDocOrTemp, getContactById, generatePdf, sendEmail, getUsersManufacturers, getDocOrderIds, createAuthStartsupply } from '../graphql/queries';
import Amplify, { API } from 'aws-amplify';
import config from "../aws-exports";
import { isEmpty, parseNumber, formatPhoneNumber, calculateData, calculateDataWO , getSantizedSublineItem} from '../Components/CommonComponents/helper';
import moment from "moment";
import * as queryString from "query-string";
import Tooltip from "rc-tooltip";
import { navigate } from "gatsby";
import email from '../assets/email.svg'
import print from '../assets/print-icon.svg'
import file from '../assets/file.svg'
import edit from '../assets/edit.svg'
import arrowDown from '../assets/arrow-down.svg'
import delivery from '../assets/delivery.svg'
import Loader from '../assets/loading.gif';
import Loader2 from '../assets/pageload.gif';
import ToastBox from "../Components/Toast/Toast";
import SendEmailModal from '../Components/Modals/SendEmailModal';
import { browserName } from "react-device-detect";
import ReactToPrint from 'react-to-print';
import { FaCircleNotch } from "react-icons/fa";
import NotesIcon from "../assets/Notes_Icon.svg";
import secureLocalStorage from "react-secure-storage";
import PageFooter from "../Components/EstimatePreview/PageFooter";
Amplify.configure(config);

export default function MaterialCostPDF(props) {
  const { consoleLog } = require('../Components/commonFunctions.js');
  const dispatch = useDispatch();
  const url_params = queryString.parse(props.location.search);
  const invoice = parseInt(url_params.invoice);
  const [serendipity, setSerendipity] = React.useState('');
  const [exportLoader, setExportLoader] = React.useState(false);
  const [stData, setStData] = React.useState(useSelector((state) => state.templateStructure));

  async function getDocument() {
    const serend = JSON.parse(secureLocalStorage.getItem("client_data")).serendipity;
    setSerendipity(serend);
    let data = {};
    let estimateData = {};
    const docId = url_params.doc_id ? url_params.doc_id : null;
    if (docId) {

      const templateRecordElastic = await API.graphql({
        query: elasticSearchFetchDoc,
        variables: {
          doc_id: docId
        }
      });

      estimateData = JSON.parse(JSON.parse(templateRecordElastic.data.elasticSearchFetchDoc)).data;
      consoleLog("data from elastic...", estimateData);
      if (Object.keys(estimateData).length == 0) {

        const templateRecord = await API.graphql({
          query: getDocumentWorkOrderData,
          variables: {
            doc_id: docId
          }
        });

        estimateData = JSON.parse(templateRecord.data.getDocumentWorkOrderData);
        consoleLog("data from db...", estimateData);
      }

      estimateData.doc_id = docId;

      setStData(estimateData);
      consoleLog('setStData');

    } else {
      estimateData = stData;
    }

    calculateTotalPages(estimateData);
    data = calculateDataWO(estimateData);

    if (data.totalQuantity <= 0) {
      return navigate(`/template?template_id=${estimateData.template_id}&doc_type_id=${estimateData.doc_type_id}&doc_id=${estimateData.doc_id}`, { state: { error: true } });
    }

    let contactInfo = {};
    if (stData.contact_info) {
      contactInfo = JSON.parse(stData.contact_info);
      contactInfo.zone_name = contactInfo && contactInfo.name ? contactInfo.name : '';
      setContact(contactInfo);
      consoleLog("contact info from elastic search.....", contactInfo);
    } else {
      const getContact = await API.graphql({
        query: getContactById,
        variables: {
          contact_id: estimateData.contact_id
        }
      });
      consoleLog('contact info from db.....', getContact);
      contactInfo = JSON.parse(getContact.data.getContactById);
      setContact(contactInfo && contactInfo[0]);
      consoleLog("contact info from db.....", contactInfo);
    }

    let ownerInfo = {};
    if (stData.property_owner_info) {
      ownerInfo = JSON.parse(stData.property_owner_info);
      setPropertyOwner(ownerInfo);
      consoleLog("property owner info from elastic search.....", ownerInfo);
    } else {
      const getPropertyOwner = await API.graphql({
        query: getContactById,
        variables: {
          contact_id: estimateData.owner_contact_id
        }
      });
      consoleLog('property owner info from db.....', getPropertyOwner);
      ownerInfo = JSON.parse(getPropertyOwner.data.getContactById);
      consoleLog('property owner info from db.....', ownerInfo);
      if (ownerInfo.length) {
        ownerInfo[0].name = ownerInfo && ownerInfo[0] ? `${ownerInfo[0].first_name} ${ownerInfo[0].last_name}` : '';
        setPropertyOwner(ownerInfo && ownerInfo[0]);
      }
    }

    setTotal(data.total);
    setLoading(false);
    pdfDownloadx(url_params.invoice, contactInfo, ownerInfo).then(
      function (response) {
        consoleLog('pdfDownloadx response>>>', response);
        if (typeof response != 'undefined') {
          const getUrl = JSON.parse(response.data.generatePdf);
          let pdf = getUrl.message.Location;
          let stDataCopy = stData;
          if (url_params.invoice == 1) {
            stDataCopy.invoice_pdf = pdf;
          } else {
            stDataCopy.pdf = pdf;
          }
          //update the document on elasticsearch
          let temp = JSON.stringify(stDataCopy);
          API.graphql({
            query: elasticSearchUpdateDocOrTemp,
            variables: {
              id: stData.doc_id,
              index: 'prodocs-documents',
              template: temp
            },
          });
          setStData(stDataCopy);
          consoleLog('setStData');
          dispatch({ type: "TEMPLATE_STRUCTURE", value: stDataCopy });
          setDownload(false);
        }
      }
    );
    serendipityFunc(estimateData);
  }

  function objectsEqual(o1, o2) {
    return Object.keys(o1).length == Object.keys(o2).length;
  }

  async function serendipityFunc(estimateData) {
    const serend = JSON.parse(secureLocalStorage.getItem("client_data")).serendipity;
    setExportLoader(true);
    if (url_params.client_id && url_params.client_secret) {
      consoleLog('serendipity_auth>', url_params.client_id);
      consoleLog('serendipity_auth>', url_params.client_secret);
      consoleLog('serendipity_auth>', url_params.doc_id);

      if (serend != "1") {
        setMessage('Sorry, you do not have permission to export.');
        setMessageType('Error');
        setShowMsg(true);
        return false;
      }

      if (typeof url_params.doc_id == 'undefined' || url_params.doc_id == '' || url_params.doc_id == null) {
        navigate('/createdoc');
      }

      const get_doc_orders = await API.graphql({
        query: getDocOrderIds,
        variables: {
          doc_id: url_params.doc_id
        }
      });
      let orders_data = JSON.parse(get_doc_orders.data.getDocOrderIds);
      consoleLog("serendipity_orders_data:", orders_data);

      let first_order = orders_data.length > 0 ? orders_data[0] : '';

      consoleLog('serendipity_first_order>', first_order);

      let json = {
        client_id: url_params.client_id,
        client_secret: url_params.client_secret,
      };

      let suppLink = '';
      if (process.env.GATSBY_ENV === 'DEV') {
        suppLink = process.env.GATSBY_SERENDIPITY_CONTRACTOR_DEV_URL;
      }
      else if (process.env.GATSBY_ENV === 'QA') {
        suppLink = process.env.GATSBY_SERENDIPITY_CONTRACTOR_QA_URL;
      }
      else if (process.env.GATSBY_ENV === 'STAGING') {
        suppLink = process.env.GATSBY_SERENDIPITY_CONTRACTOR_STAGING_URL;
      }
      else if (process.env.GATSBY_ENV === 'PRODUCTION') {
        suppLink = process.env.GATSBY_SERENDIPITY_CONTRACTOR_PRODUCTION_URL;
      }
      else {
        suppLink = process.env.GATSBY_SERENDIPITY_CONTRACTOR_STAGING_URL;
      }

      let client_credentials = await fetch(`${suppLink}/api/rest/oauth2/token/client_credentials`, {
        method: 'POST',
        body: JSON.stringify(json),
        headers: { 'Content-Type': 'application/json' },
      })
        .then(res => res.json())
        .then(json => {
          return json;
        });
      consoleLog('serendipity_client_credentials>', client_credentials);
      if (client_credentials.data) {
        consoleLog('serendipity_client_credentials found!!!', client_credentials.data);
        const date = new Date();
        let time = date.getTime();
        client_credentials.data.expires_at = time + client_credentials.data.expires_in;
        consoleLog('serendipity client_credentials data>', client_credentials.data);
        secureLocalStorage.setItem('startsupply_auth_data', client_credentials.data);
        let doc_id = url_params.doc_id;
        if (url_params.invoice != 'undefined' && url_params.invoice == 1) {
          navigate('/materialCostPdf?invoice=1&preview=wrkpdf&doc_id=' + doc_id + '&serendipity_export=1');
        } else {
          navigate('/materialCostPdf?preview=wrkpdf&doc_id=' + doc_id + '&serendipity_export=1');
        }
        consoleLog('serendipity_set_exp');
        //serendipity export
        if (client_credentials.data.access_token) {
          consoleLog('serendipity_token', client_credentials.data.access_token);
          consoleLog('serendipity_token_type', client_credentials.data.token_type);
          const header = ['Authorization: Bearer ' + client_credentials.data.access_token];
          const header_json = ['Authorization: Bearer ' + client_credentials.data.access_token, 'Content-Type: application/json'];
          consoleLog('serendipity_header', header);
          consoleLog('serendipity_header_json', header_json);
          //empty cart
          let empty_cart = await fetch(`${suppLink}/api/rest/cart/empty`, {
            method: 'DELETE',
            headers: { 'Authorization': 'Bearer ' + client_credentials.data.access_token },
          })
            .then(res => res.json())
            .then(json => {
              return json;
            });
          consoleLog('serendipity_empty_cart>', empty_cart);
          ///products
          let products = [];
          let stDataCopy = estimateData;
          consoleLog('serendipity_stDataCopy>', stDataCopy);
          for (let i = 0; i < stDataCopy.structures.length; i++) {
            consoleLog(i + ' i>>>', stDataCopy.structures[i]);
            for (let j = 0; j < stDataCopy.structures[i].categories.length; j++) {
              consoleLog(j + ' j>>>', stDataCopy.structures[i].categories[j]);
              for (let k = 0; k < stDataCopy.structures[i].categories[j]?.line_items.length; k++) {
                consoleLog(k + ' k>>>', stDataCopy.structures[i].categories[j].line_items[k]);
                let prodObj = {};
                prodObj.name = stDataCopy.structures[i].categories[j].line_items[k].name;
                prodObj.description = stDataCopy.structures[i].categories[j].line_items[k].description;
                prodObj.line_item_code = stDataCopy.structures[i].categories[j].line_items[k].line_item_code;
                prodObj.quantity = calculateAdjQuantity(stDataCopy.structures[i].categories[j].line_items[k]);
                prodObj.manufacturer = stDataCopy.structures[i].categories[j].line_items[k].manufacturer;
                prodObj.color = stDataCopy.structures[i].categories[j].line_items[k].color;
                prodObj.unit = stDataCopy.structures[i].categories[j].line_items[k].unit;
                products.push(prodObj);
                prodObj = {};
              }
            }
          }
          consoleLog('serendipity_products initial>', products);

          let epm_result = await fetch(`${suppLink}/api/rest/export_products_manufacturers`, {
            method: 'POST',
            body: JSON.stringify(products),
            headers: { 'Authorization': 'Bearer ' + client_credentials.data.access_token, 'Content-Type': 'application/json' },
          })
            .then(res => res.json())
            .then(json => {
              return json;
            });
          consoleLog('serendipity_epm_result>', epm_result);

          products = epm_result.data;

          let rs_order_id = typeof first_order.order_id != 'undefined' ? first_order.order_id : '';
          let rs_address = typeof first_order.address != 'undefined' ? first_order.address : '';

          let cart_products = [];
          for (let n = 0; n < products.length; n++) {
            if (products[n].product_id && products.quantity != 0) {
              let tempObj = {
                product_id: products[n].product_id,
                quantity: products[n].quantity,
                doc_id: doc_id,
                attributes: {
                  manufacturer: products[n].manufacturer,
                  unit: products[n].unit,
                  color: products[n].color,
                  rs_order_id: rs_order_id,
                  prodoc_address: rs_address
                }
              }
              cart_products.push(tempObj);
            }
          }
          consoleLog('serendipity_cart_products', cart_products);
          if (cart_products.length > 0) {
            let bulk = await fetch(`${suppLink}/api/rest/cart_bulk`, {
              method: 'POST',
              body: JSON.stringify(cart_products),
              headers: { 'Authorization': 'Bearer ' + client_credentials.data.access_token, 'Content-Type': 'application/json' },
            })
              .then(res => res.json())
              .then(json => {
                return json;
              });
            consoleLog('serendipity_bulk', bulk);

            let address_id = 0;
            if (rs_address != "") {
              let header_json = { 'Authorization': 'Bearer ' + client_credentials.data.access_token, 'Content-Type': 'application/json' };
              address_id = await set_shipping_address(header_json, rs_order_id);
            }
            consoleLog('serendipity_address_id', address_id);
            if (url_params.invoice != 'undefined' && url_params.invoice == 1) {
              navigate('/materialCostPdf?invoice=1&preview=wrkpdf&doc_id=' + doc_id);
            } else {
              navigate('/materialCostPdf?preview=wrkpdf&doc_id=' + doc_id);
            }
            setExportLoader(false);
            window.location.replace(suppLink + "/index.php?route=checkout/cart&del_mcart=true&address_id=" + address_id);
            return false;
          } else {
            setMessage('Sorry, no products selected to export.');
            setMessageType('Error');
            setShowMsg(true);
            setExportLoader(false);
            return false;
          }
        } else {
          setMessage('Access Token Not Found.');
          setMessageType('Error');
          setShowMsg(true);
          setExportLoader(false);
          return false;
        }
      } else {
        consoleLog('serendipity_client_credentials not found!!!');
        setMessage('No Access Token From Serendipity.');
        setMessageType('Error');
        setShowMsg(true);
      }
    }
    if (url_params.serendipity_error) {
      consoleLog('serendipity_error>', url_params.serendipity_error);
      setMessage(url_params.serendipity_error);
      setMessageType('Error');
      setShowMsg(true);
    }
    setExportLoader(false);
  }

  const PHP = {
    stdClass: function () { },
    stringify(val) {
      const hash = new Map([[Infinity, "d:INF;"], [-Infinity, "d:-INF;"], [NaN, "d:NAN;"], [null, "N;"], [undefined, "N;"]]);
      const utf8length = str => str ? encodeURI(str).match(/(%.)?./g).length : 0;
      const serializeString = (s, delim = '"') => `${utf8length(s)}:${delim[0]}${s}${delim[delim.length - 1]}`;
      let ref = 0;

      function serialize(val, canReference = true) {
        if (hash.has(val)) return hash.get(val);
        ref += canReference;
        if (typeof val === "string") return `s:${serializeString(val)};`;
        if (typeof val === "number") return `${Math.round(val) === val ? "i" : "d"}:${("" + val).toUpperCase().replace(/(-?\d)E/, "$1.0E")};`;
        if (typeof val === "boolean") return `b:${+val};`;
        const a = Array.isArray(val) || val.constructor === Object;
        hash.set(val, `${"rR"[+a]}:${ref};`);
        if (typeof val.serialize === "function") {
          return `C:${serializeString(val.constructor.name)}:${serializeString(val.serialize(), "{}")}`;
        }
        const vals = Object.entries(val).filter(([k, v]) => typeof v !== "function");
        return (a ? "a" : `O:${serializeString(val.constructor.name)}`)
          + `:${vals.length}:{${vals.map(([k, v]) => serialize(a && /^\d{1,16}$/.test(k) ? +k : k, false) + serialize(v)).join("")}}`;
      }
      return serialize(val);
    },
    // Provide in second argument the classes that may be instantiated
    //  e.g.  { MyClass1, MyClass2 }
    parse(str, allowedClasses = {}) {
      allowedClasses.stdClass = PHP.stdClass; // Always allowed.
      let offset = 0;
      const values = [null];
      const specialNums = { "INF": Infinity, "-INF": -Infinity, "NAN": NaN };

      const kick = (msg, i = offset) => { throw new Error(`Error at ${i}: ${msg}\n${str}\n${" ".repeat(i)}^`) }
      const read = (expected, ret) => expected === str.slice(offset, offset += expected.length) ? ret
        : kick(`Expected '${expected}'`, offset - expected.length);

      function readMatch(regex, msg, terminator = ";") {
        read(":");
        const match = regex.exec(str.slice(offset));
        if (!match) kick(`Exected ${msg}, but got '${str.slice(offset).match(/^[:;{}]|[^:;{}]*/)[0]}'`);
        offset += match[0].length;
        return read(terminator, match[0]);
      }

      function readUtf8chars(numUtf8Bytes, terminator = "") {
        const i = offset;
        while (numUtf8Bytes > 0) {
          const code = str.charCodeAt(offset++);
          numUtf8Bytes -= code < 0x80 ? 1 : code < 0x800 || code >> 11 === 0x1B ? 2 : 3;
        }
        return numUtf8Bytes ? kick("Invalid string length", i - 2) : read(terminator, str.slice(i, offset));
      }

      const create = className => !className ? {}
        : allowedClasses[className] ? Object.create(allowedClasses[className].prototype)
          : new { [className]: function () { } }[className]; // Create a mock class for this name
      const readBoolean = () => readMatch(/^[01]/, "a '0' or '1'", ";");
      const readInt = () => +readMatch(/^-?\d+/, "an integer", ";");
      const readUInt = terminator => +readMatch(/^\d+/, "an unsigned integer", terminator);
      const readString = (terminator = "") => readUtf8chars(readUInt(':"'), '"' + terminator);

      function readDecimal() {
        const num = readMatch(/^-?(\d+(\.\d+)?(E[+-]\d+)?|INF)|NAN/, "a decimal number", ";");
        return num in specialNums ? specialNums[num] : +num;
      }

      function readKey() {
        const typ = str[offset++];
        return typ === "s" ? readString(";")
          : typ === "i" ? readUInt(";")
            : kick("Expected 's' or 'i' as type for a key, but got ${str[offset-1]}", offset - 1);
      }

      function readObject(obj) {
        for (let i = 0, length = readUInt(":{"); i < length; i++) obj[readKey()] = readValue();
        return read("}", obj);
      }

      function readArray() {
        const obj = readObject({});
        return Object.keys(obj).some((key, i) => key != i) ? obj : Object.values(obj);
      }

      function readCustomObject(obj) {
        if (typeof obj.unserialize !== "function") kick(`Instance of ${obj.constructor.name} does not have an "unserialize" method`);
        obj.unserialize(readUtf8chars(readUInt(":{")));
        return read("}", obj);
      }

      function readValue() {
        const typ = str[offset++].toLowerCase();
        const ref = values.push(null) - 1;
        const val = typ === "n" ? read(";", null)
          : typ === "s" ? readString(";")
            : typ === "b" ? readBoolean()
              : typ === "i" ? readInt()
                : typ === "d" ? readDecimal()
                  : typ === "a" ? readArray()                            // Associative array
                    : typ === "o" ? readObject(create(readString()))       // Object
                      : typ === "c" ? readCustomObject(create(readString())) // Custom serialized object
                        : typ === "r" ? values[readInt()]                      // Backreference
                          : kick(`Unexpected type ${typ}`, offset - 1);
        if (typ !== "r") values[ref] = val;
        return val;
      }

      const val = readValue();
      if (offset !== str.length) kick("Unexpected trailing character");
      return val;
    }
  }

  async function set_shipping_address(header_json, order_id) {
    consoleLog('set_shipping_address', header_json);
    consoleLog('set_shipping_address', order_id);

    let suppLink = '';
    if (process.env.GATSBY_ENV === 'DEV') {
      suppLink = process.env.GATSBY_SERENDIPITY_CONTRACTOR_DEV_URL;
    }
    else if (process.env.GATSBY_ENV === 'QA') {
      suppLink = process.env.GATSBY_SERENDIPITY_CONTRACTOR_QA_URL;
    }
    else if (process.env.GATSBY_ENV === 'STAGING') {
      suppLink = process.env.GATSBY_SERENDIPITY_CONTRACTOR_STAGING_URL;
    }
    else if (process.env.GATSBY_ENV === 'PRODUCTION') {
      suppLink = process.env.GATSBY_SERENDIPITY_CONTRACTOR_PRODUCTION_URL;
    }
    else {
      suppLink = process.env.GATSBY_SERENDIPITY_CONTRACTOR_STAGING_URL;
    }

    const osl = await API.graphql({
      query: getOderScopeLoc,
      variables: {
        order_id: order_id
      }
    });

    consoleLog('set_shipping_address osl>>>', osl);
    let scope = JSON.parse(osl.data.getOderScopeLoc).body;
    consoleLog('set_shipping_address scope>>>', scope);

    if (!scope || typeof scope.address_components == 'undefined' || !scope.address_components) {
      consoleLog('set_shipping_address scope.address_components null');
      return 0;
    }

    let client_id = JSON.parse(secureLocalStorage.getItem('client_data')).client_id;
    if (scope.ordered_by != client_id) {
      return 0;
    }

    if (scope.address_components) {
      let address_components = PHP.parse(scope.address_components);

      consoleLog('address_components', address_components);

      let route_component = 1;
      let country_component = 6;
      let postcode_component = 7;

      if (address_components) {
        for (let k = 0; k < address_components.length; k++) {
          let component = address_components[k];
          if (typeof component.types[0] != 'undefined' && component.types[0] == 'country') {
            country_component = k;
            consoleLog('country_component', country_component);
          }

          if (typeof component.types[0] != 'undefined' && component.types[0] == 'postal_code') {
            postcode_component = k;
            consoleLog('postcode_component', postcode_component);
          }

          if (typeof component.types[0] != 'undefined' && component.types[0] == 'route') {
            route_component = k;
            consoleLog('route_component', route_component);
          }
        }
      }

      let country = "";
      let city = ""
      let postcode = ""
      var geocoded_address = "";
      var geocoded_address_route = "";

      if (typeof address_components[country_component] != 'undefined' && (address_components[country_component] != '' || address_components[country_component] != null)) {
        let country_name = address_components[country_component].short_name;
        consoleLog('country_name', country_name);
        let country_json_params = { code: country_name };

        country = await fetch(suppLink + '/api/rest/countries/code/' + country_name, {
          method: 'GET',
          headers: header_json,
        })
          .then(res => res.json())
          .then(json => {
            return json;
          });
      }

      consoleLog('country>>>', country);

      city = (typeof address_components[3] != 'undefined' && address_components[3] != '' && address_components[3] != null) ? address_components[3].long_name : '';
      postcode = (typeof address_components[postcode_component] != 'undefined' && address_components[postcode_component] != '' && address_components[postcode_component] != null) ? address_components[postcode_component].long_name : '';
      geocoded_address = (typeof address_components[1] != 'undefined' && address_components[1] != '' && address_components[1] != null) ? address_components[1].long_name : '';
      geocoded_address_route = (typeof address_components[route_component] != 'undefined' && address_components[route_component] != '' && address_components[route_component] != null) ? address_components[route_component] : geocoded_address;

      let f_name = JSON.parse(secureLocalStorage.getItem('client_data')).first_name;
      let l_name = JSON.parse(secureLocalStorage.getItem('client_data')).last_name;

      consoleLog('city', city);
      consoleLog('postcode', postcode);
      consoleLog('geocoded_address', geocoded_address);

      var data_json = {
        firstname: f_name,
        lastname: l_name ? l_name : "N/A",
        city: city,
        address_1: geocoded_address,
        country_id: country.data.country_id,
        postcode: postcode,
        zone_id: 'AT',
        sort_order: 0,
        custom_field: [
          {
            order_id: order_id,
          }
        ]
      };

      consoleLog('data_json', data_json);

      var existing_addresses = await fetch(suppLink + '/api/rest/shippingaddress', {
        method: 'GET',
        headers: header_json,
      })
        .then(res => res.json())
        .then(json => {
          return json;
        });

      consoleLog('existing_addresses', existing_addresses);

      let existing_address = false;

      if (typeof existing_addresses.data.addresses != 'undefined') {
        for (let l = 0; l < existing_addresses.data.addresses.length; l++) {
          if (!existing_address && (existing_addresses.data.addresses[l].address_1 == geocoded_address || existing_addresses.data.addresses[l].address_1 == geocoded_address_route)) {
            return existing_addresses.data.addresses[l].address_id;
          }
        }
      }

      if (!existing_address) {
        let result = await fetch(suppLink + '/api/rest/shippingaddress', {
          method: 'POST',
          body: JSON.stringify(data_json),
          headers: header_json,
        })
          .then(res => res.json())
          .then(json => {
            return json;
          });

        consoleLog('final result>>>', result);

        if (result.data.prodoc_address_id) {
          return result.data.prodoc_address_id;
        }
      }
    }

    return 0;
  }

  async function exportToStartSupply() {
    let doc_id = url_params.doc_id;
    let backLink = '';
    let suppLink = '';
    if (process.env.GATSBY_ENV === 'DEV') {
      backLink = process.env.GATSBY_DEV_BASE_URL;
      suppLink = process.env.GATSBY_SERENDIPITY_CONTRACTOR_DEV_URL;
    }
    else if (process.env.GATSBY_ENV === 'QA') {
      backLink = process.env.GATSBY_QA_BASE_URL;
      suppLink = process.env.GATSBY_SERENDIPITY_CONTRACTOR_QA_URL;
    }
    else if (process.env.GATSBY_ENV === 'STAGING') {
      backLink = process.env.GATSBY_STAGING_BASE_URL;
      suppLink = process.env.GATSBY_SERENDIPITY_CONTRACTOR_STAGING_URL;
    }
    else if (process.env.GATSBY_ENV === 'PRODUCTION') {
      backLink = process.env.GATSBY_PRODUCTION_BASE_URL;
      suppLink = process.env.GATSBY_SERENDIPITY_CONTRACTOR_PRODUCTION_URL;
    }
    else {
      backLink = 'http://localhost:8000/';
      suppLink = process.env.GATSBY_SERENDIPITY_CONTRACTOR_STAGING_URL;
    }
    consoleLog('exportToStartSupply env>', process.env.GATSBY_ENV);
    consoleLog('exportToStartSupply backLink>', backLink);
    consoleLog('exportToStartSupply suppLink>', suppLink);

    let prms = `?preview=wrkpdf&doc_id=${doc_id}`;
    if (url_params.invoice != 'undefined' && url_params.invoice == 1) {
      prms = `?invoice=1&preview=wrkpdf&doc_id=${doc_id}`;
    }

    consoleLog('exportToStartSupply redirecl url> ' + suppLink + '/index.php?route=account/api/approval&redirect=' + backLink + 'materialCostPdf' + encodeURIComponent(prms));
    window.open(suppLink + '/index.php?route=account/api/approval&redirect=' + backLink + 'materialCostPdf' + encodeURIComponent(prms));
  }

  async function pdfDownloadx(invoice, contactInfo = [], ownerInfo = []) {
    let pdf;
    let data = {};

    if (invoice == 1) {
      pdf = stData.invoice_pdf;
    } else {
      pdf = stData.pdf;
    }
    consoleLog('pdfDownloadx invoice>>>', invoice);
    consoleLog('pdfDownloadx stData>>>', stData);
    consoleLog('pdfDownloadx pdf>>>', pdf);

    consoleLog('pdfDownloadx contactInfo>>>', contactInfo);
    consoleLog('pdfDownloadx contact>>>', contact);
    let c_info = {};
    if (typeof contact == 'object' && Object.keys(contact).length != 0) {
      consoleLog('pdfDownloadx contact if>>>', contact);
      c_info = contact;
    } else {
      consoleLog('pdfDownloadx contact else>>>', typeof contactInfo);
      if (Array.isArray(contactInfo)) {
        c_info = contactInfo[0];
        consoleLog('pdfDownloadx contact if>>>', contactInfo[0]);
      } else {
        c_info = contactInfo;
        consoleLog('pdfDownloadx contact else>>>', contactInfo);
      }
    }
    consoleLog('pdfDownloadx c_info>>>', c_info);

    consoleLog('pdfDownloadx ownerInfo>>>', ownerInfo);
    consoleLog('pdfDownloadx ownerInfo>>>', Object.keys(propertyOwner).length);
    consoleLog('pdfDownloadx propertyOwner>>>', propertyOwner);
    let o_info = {};
    if (typeof propertyOwner == 'object' && Object.keys(propertyOwner).length !== 0) {
      consoleLog('pdfDownloadx propertyOwner if>>>', propertyOwner);
      o_info = propertyOwner;
    } else {
      consoleLog('pdfDownloadx propertyOwner else>>>', typeof ownerInfo);
      if (Array.isArray(ownerInfo)) {
        o_info = ownerInfo[0];
        consoleLog('pdfDownloadx propertyOwner else if>>>', ownerInfo[0]);
      } else {
        o_info = ownerInfo;
        consoleLog('pdfDownloadx propertyOwner else else>>>', ownerInfo);
      }
    }
    consoleLog('pdfDownloadx o_info>>>', o_info);

    if (typeof pdf != 'undefined' && pdf != '') {
      consoleLog('pdfDownloadx abort download>>>');
    } else {
      consoleLog('pdfDownloadx start download>>>');
      if (typeof stData.structures != 'undefined') {
        setDownload(true);
        data.propertyOwner = o_info;
        data.contact = c_info;
        data.docDetail = stData;
        data.calcualatedData = calculateDataWO(stData);
        data.invoice = invoice;
        data.client_default_data = JSON.parse(secureLocalStorage.getItem("client_data"));
        consoleLog('pdfDownloadx start generation>>>', data);

        try {
          //generate the PDF
          const response = API.graphql({
            query: generatePdf,
            variables: {
              docData: JSON.stringify(data)
            }
          });
          return response;
        }
        catch (err) {
          consoleLog('pdfDownloadx abort generation>>>', err);
          return false;
        }

      } else {
        consoleLog('pdfDownloadx abort generation>>>');

      }
    }
  }

  async function pdfDownload(sendMessage = 0) {
    let pdf;
    let data = {};

    if (invoice === 1) {
      pdf = stData.invoice_pdf;
    } else {
      pdf = stData.pdf;
    }
    if (sendMessage === 0) {
      setDownload(true);
    }

    if (isEmpty(pdf)) {
      data.propertyOwner = propertyOwner;
      data.contact = contact;
      data.docDetail = stData;
      data.calcualatedData = calculateDataWO(stData);
      data.invoice = invoice;
      data.client_default_data = JSON.parse(secureLocalStorage.getItem("client_data"));
      consoleLog('pdfDownload start generation>>>', data);

      let response;
      try {
        response = await API.graphql({
          query: generatePdf,
          variables: {
            docData: JSON.stringify(data)
          }
        });
      }
      catch (err) {
        consoleLog('Error pdfDownload start generation>>>', err);
        setDownload(false);
        return false;
      }

      const getUrl = JSON.parse(response?.data?.generatePdf)
      pdf = getUrl?.message?.Location;

      if (invoice === 1) {
        stData.invoice_pdf = pdf;
      } else {
        stData.pdf = pdf;
      }

      let temp = JSON.stringify(stData);

      API.graphql({
        query: elasticSearchUpdateDocOrTemp,
        variables: {
          id: stData.doc_id,
          index: 'prodocs-documents',
          template: temp
        },
      });

      dispatch({ type: "TEMPLATE_STRUCTURE", value: stData });
    }

    if (sendMessage === 1) {
      setPdfUrl(pdf);
    } else {
      setDownload(false);
      window.open(pdf, "_blank");
    }
  }

  const emailPopup = async () => {
    setEmailPopupShow(true);
    pdfDownload(1);
  }

  async function sendDoc(emails, subject, message, url) {

    let validEmail = true;
    let toEmails = emails.split(',');
    let client = JSON.parse(secureLocalStorage.getItem('client_data'));

    if (isEmpty(emails) || isEmpty(subject) || isEmpty(message)) {
      setError('Fields with * are required');
    } else if (isEmpty(url)) {
      setError('Something went wrong please try again!');
    } else {
      for (const email of toEmails) {
        if (!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email.trim()))) {
          validEmail = false;
          break;
        }
      }

      if (validEmail) {
        setDocSend(true);

        const response = await API.graphql({
          query: sendEmail,
          variables: {
            subject: subject,
            to_email: emails,
            url_download: url,
            email_msg: message,
            client_email: client.email,
            client_id: client.client_id
          }
        });

        const result = JSON.parse(response.data.sendEmail);
        if (result.status === 422) {
          setMessage(result.message);
        }
        else if (result[0] && result[0].status === 'sent') {
          setMessage('Your document has been emailed to the requested recipients!');
          setMessageType('Success');
        } else {
          setMessage('We were unable to send the PDF document via email. Please try downloading and emailing the PDF manually.');
          setMessageType('Error');
        }
        setPdfUrl('');
        setShowMsg(true);
        setDocSend(false);
        setEmailPopupShow(false);
      } else {
        setError('Invalid Email address');
      }
    }
  }

  function redirectPage(invoice) {
    consoleLog('redirectPage');
    if (invoice == 1) {
      navigate('/materialCostPdf?invoice=1&preview=wrkpdf&doc_id=' + url_params.doc_id);
    } else {
      navigate('/materialCostPdf?preview=wrkpdf&doc_id=' + url_params.doc_id);
    }
    pdfDownloadx(invoice).then(
      function (response) {
        consoleLog('pdfDownloadx response>>>', response);
        consoleLog('pdfDownloadx invoice>>>', invoice);
        if (typeof response != 'undefined') {
          const getUrl = JSON.parse(response?.data?.generatePdf);
          let pdf = getUrl?.message?.Location;
          let stDataCopy = stData;
          if (invoice == 1) {
            stDataCopy.invoice_pdf = pdf;
          } else {
            stDataCopy.pdf = pdf;
          }
          //update the document on elasticsearch
          let temp = JSON.stringify(stDataCopy);
          API.graphql({
            query: elasticSearchUpdateDocOrTemp,
            variables: {
              id: stData.doc_id,
              index: 'prodocs-documents',
              template: temp
            },
          });
          setStData(stDataCopy);
          consoleLog('setStData');
          dispatch({ type: "TEMPLATE_STRUCTURE", value: stDataCopy });
          setDownload(false);
        }
      }
    );
  }
  const ITEMS_PER_PAGE = 30;
  const FIRST_PAGE_ITEMS = 15;
  function calculateTotalPages(estimateData) {
    let pages = 0;
    estimateData.structures.map((str) => {
      str.categories.map((category , catIndex) => {
        let itemPerPage = catIndex ==0?FIRST_PAGE_ITEMS:ITEMS_PER_PAGE;
        const lineItemsTotal = category.line_items.reduce(function(totallines , rec){
          return totallines + 1 + getSantizedSublineItem(rec.sub_line_item).length;
        },0); 
        pages += Math.ceil(lineItemsTotal / itemPerPage);
      });
    });
    setTotalPages(pages);
  }

  function calculateAdjQuantity(line_item) {
    let ratio_fromx = line_item.ratio_from ? line_item.ratio_from : 1;
    let ratio_tox = line_item.ratio_to ? line_item.ratio_to : 1;
    let quantityx = 1 * line_item.quantity;
    let wastex = 1 * line_item.waste;
    let convert_roundx = 1 * line_item.convert_round;
    let quant = quantityx * ratio_tox / ratio_fromx;
    let adjusted_quantityx = ((100 + wastex) * quant) / 100;
    if (convert_roundx) {
      adjusted_quantityx = Math.ceil(adjusted_quantityx);
      adjusted_quantityx = adjusted_quantityx.toFixed(2);
      adjusted_quantityx = Number.parseFloat(adjusted_quantityx);
    } else {
      adjusted_quantityx = Math.round(adjusted_quantityx * 100) / 100;
      adjusted_quantityx = adjusted_quantityx.toFixed(2);
      adjusted_quantityx = Number.parseFloat(adjusted_quantityx);
    }
    return adjusted_quantityx;
  }

  const [loading, setLoading] = React.useState(true);
  const [total, setTotal] = React.useState(0);
  const [contact, setContact] = React.useState([]);
  const [propertyOwner, setPropertyOwner] = React.useState([]);
  const [download, setDownload] = React.useState(false);
  const [docSend, setDocSend] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [error, setError] = useState('');
  const [emailPopupShow, setEmailPopupShow] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [defContactFirstName, setdefContactFirstName] = useState("")
  const [defContactLastName, setdefContactLastName] = useState("")
  const [defContactEmail, setdefContactEmail] = useState("")
  const [defContactPhoneNo, setdefContactPhoneNo] = useState("")
  const [companyName, setCompanyName] = useState("")


  useEffect(() => {
    setdefContactFirstName(JSON.parse(secureLocalStorage.getItem("client_data")).prodocs_work_order_contact_first_name)
    setdefContactLastName(JSON.parse(secureLocalStorage.getItem("client_data")).prodocs_work_order_contact_last_name)
    setdefContactEmail(JSON.parse(secureLocalStorage.getItem("client_data")).prodocs_work_order_contact_email)
    setdefContactPhoneNo(JSON.parse(secureLocalStorage.getItem("client_data")).prodocs_work_order_contact_phone)
    setCompanyName(JSON.parse(secureLocalStorage.getItem("client_data")).company)
  }, [])

  const [defLogo, setDefLogo] = useState('//roofscope.com/app/view/theme/polyscope/image/logo.png#file_id=8');
  const [defColor, setDefColor] = useState('#da8227');

  let pageCount = 0;
  const [totalPages, setTotalPages] = React.useState(0);

  consoleLog('my browser is', browserName);

  useEffect(() => {
    let dLogo = JSON.parse(secureLocalStorage.getItem('client_data')).logo;
    if (dLogo) {
      setDefLogo(dLogo);
    }
    let dColor = JSON.parse(secureLocalStorage.getItem('client_data')).color;
    if (dColor) {
      setDefColor(dColor);
    }
    getDocument();
  }, []);

  const options = stData.options ? JSON.parse(stData.options) : null;
  const color = options && options.color ? options.color : defColor;
  const componentRef = useRef();
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=756, initial-scale=1, shrink-to-fit=no" />
        <title>Prodocs</title>
      </Helmet>
      {emailPopupShow && <SendEmailModal
        show={emailPopupShow}
        setModalShow={setEmailPopupShow}
        error={error}
        docSend={docSend}
        pdfUrl={pdfUrl}
        onSendDoc={sendDoc}
      />}
      <ToastBox
        setShow={setShowMsg}
        show={showMsg}
        Text={message}
        Type={messageType}
      />
      {loading ? (
        <LoadingContainer>
          <img className="loader" src={Loader} />
        </LoadingContainer>
      ) : (
        <PDFS ref={componentRef}>
          <div className="mainDiv">
            <div className="downloadPDF-section no-print">
              <Tooltip
                overlay={
                  <div
                    className="d-flex flex-column justify-content-center pdf-tooltip"
                  >
                    <p style={{ marginBottom: '0', fontWeight: 'bold' }}>Edit</p>
                  </div>
                }
                placement="bottom"
              >
                <a href={`/template?template_id=${stData.template_id}&&doc_type_id=${stData.doc_type_id}&&doc_id=${stData.doc_id}`} className="button-section">
                  <img src={edit} alt={'edit-icon'} />
                </a>
              </Tooltip>
              <ReactToPrint
                trigger={() =>
                  <Tooltip
                    overlay={
                      <div className="d-flex flex-column justify-content-center pdf-tooltip">
                        <p style={{ marginBottom: '0', fontWeight: 'bold' }}>Print</p>
                      </div>
                    }
                    placement="bottom"
                  >
                    <div className={browserName == 'Chrome' ? "button-section" : ""}>
                      <img src={print} alt={'print-icon'} />
                    </div>
                  </Tooltip>
                }
                content={() => componentRef.current}
                bodyClass="print-body"
                pageStyle={printStyles}
              />
              <Tooltip
                overlay={
                  <div
                    className="d-flex flex-column justify-content-center pdf-tooltip"
                  >
                    <p style={{ marginBottom: '0', fontWeight: 'bold' }}>Download</p>
                  </div>
                }
                placement="bottom"
              >
                {download ?
                  <div className="button-section">
                    <FaCircleNotch size={22} className="awesome_spinner" style={{ color: 'white' }} />
                  </div> :
                  <div className="button-section" onClick={() => pdfDownload(0)}>
                    <img src={arrowDown} alt={'arrowDown-icon'} />
                  </div>
                }
              </Tooltip>
              <Tooltip
                overlay={
                  <div
                    className="d-flex flex-column justify-content-center pdf-tooltip"
                  >
                    <p style={{ marginBottom: '0', fontWeight: 'bold' }}>Export</p>
                  </div>
                }
                placement="bottom"
              >
                {serendipity == "1"
                  ?
                  <a className="button-section" onClick={exportToStartSupply}>
                    {exportLoader ?
                      <FaCircleNotch size={22} className="awesome_spinner" style={{ color: 'white' }} />
                      :
                      <img className="rotate-img" src={arrowDown} alt={'arrowDown-icon'} />
                    }
                  </a>
                  :
                  <></>
                }
              </Tooltip>
              <Tooltip
                overlay={
                  <div
                    className="d-flex flex-column justify-content-center pdf-tooltip"
                  >
                    <p style={{ marginBottom: '0', fontWeight: 'bold' }}>Email</p>
                  </div>
                }
                placement="bottom"
              >
                <div className="button-section" onClick={() => emailPopup()}>
                  <img src={email} alt={'email-icon'} />
                </div>
              </Tooltip>

              {
                invoice === 1 ?
                  <Tooltip
                    overlay={
                      <div
                        className="d-flex flex-column justify-content-center pdf-tooltip"
                      >
                        <p style={{ marginBottom: '0', fontWeight: 'bold' }}>Work Order</p>
                      </div>
                    }
                    placement="bottom"
                  >
                    <div className="button-slip" onClick={() => redirectPage(0)}>
                      <img src={delivery} alt={'delivery-icon'} />
                    </div>
                  </Tooltip>
                  :
                  <Tooltip
                    overlay={
                      <div
                        className="d-flex flex-column justify-content-center pdf-tooltip"
                      >
                        <p style={{ marginBottom: '0', fontWeight: 'bold' }}>Material Cost</p>
                      </div>
                    }
                    placement="bottom"
                  >
                    <div className="button-calculator" onClick={() => redirectPage(1)}>
                      <img src={file} alt={'file-icon'} />
                    </div>
                  </Tooltip>
              }
            </div>
            {stData && stData.structures && stData.structures.map((item, index) => {
              return (<MainDiv key={index} className="pdf-container">
                {item.categories.map((category, category_index) => {
                  const sum = category.line_items.reduce((total, record) => {
                    return total + parseNumber(record.quantity);
                  }, 0);

                  const lineItemsTotal = category.line_items.reduce((totallines, rec) => {
                    let sublineItems = getSantizedSublineItem(rec?.sub_line_item); 
                    let lineItemsCount = 1;
                    if (sublineItems.length > 0) {
                      const sublineItemCount = sublineItems.reduce((total, rec1) => {
                        let maxLen = 65;
                        if(invoice){
                          maxLen = 46;
                        }
                        const sublinesno = rec1.length > maxLen ? Math.ceil(rec1.length / maxLen) : 1;
                        return total + sublinesno;
                      }, 0);
                      lineItemsCount += sublineItemCount;
                    }
                    return totallines + lineItemsCount;
                  }, 0);

                  console.log("noOfSublineItems ", lineItemsTotal);

                  if (sum > 0) {
                    let pagesBasedOnSingleCat = 0;

                    if (lineItemsTotal <= FIRST_PAGE_ITEMS) { 
                      pagesBasedOnSingleCat = 1;
                    } else { 
                      const remainingLines = lineItemsTotal - FIRST_PAGE_ITEMS; 
                      const additionalPages = Math.ceil(remainingLines / ITEMS_PER_PAGE);

                      pagesBasedOnSingleCat = 1 + additionalPages;
                    }

                    console.log(`Category ${category_index + 1}: Total Pages =`, pagesBasedOnSingleCat);

                    const renderedPages = [];
                    let remainingItems = [...category.line_items];
                    for (let iter = 0; iter < pagesBasedOnSingleCat; iter++) {
                      const itemsPerPage = iter === 0 ? FIRST_PAGE_ITEMS : ITEMS_PER_PAGE;
                      let pageLinesUsed = 0;
                      const currentItems = [];
                      while (remainingItems.length > 0 && pageLinesUsed < itemsPerPage) {
                        const currentItem = remainingItems[0];
                        const subLineItems = getSantizedSublineItem(currentItem.sub_line_item);
                        const sublineItemCount = subLineItems.reduce((total, rec1) => {
                          let maxLen = 65;
                          if(invoice){
                            maxLen = 46;
                          }
                          const sublinesno = rec1.length > maxLen ? Math.ceil(rec1.length / maxLen) : 1;
                          return total + sublinesno;
                        }, 0);
                        const linesForItem = 1 + sublineItemCount;
                        if (pageLinesUsed + linesForItem <= itemsPerPage) {
                          currentItems.push(currentItem);
                          pageLinesUsed += linesForItem;
                          remainingItems.shift();
                        } else {
                          break;
                        }
                      }
                      console.log(`Page ${iter + 1} (Category ${category_index + 1}):`, currentItems);
                      ++pageCount;
                      let count = 0;
                      renderedPages.push(<Wrapper key={category_index}>
                    { iter ==0?
                      <div>
                        <div className="container-pdf">
                          <div className="logo-pdf">
                            <img src={options?.logo ? options?.logo : defLogo} alt="" />
                          </div>
                        </div>
                        <div class="header-contact">
                          {contact ?
                            <p style={{ textAlign: 'center' }}>
                              {contact?.company ? contact?.company : ''}{contact?.phone ? ' | ' + formatPhoneNumber(contact?.phone) : ''}
                            </p>
                            :
                            <p style={{ textAlign: 'center' }}>
                              {defContactPhoneNo ? formatPhoneNumber(defContactPhoneNo) : ''}
                            </p>
                          }
                        </div>

                        <div className="container-pdf">
                          <div className="row w-100">
                            <div className="col-4 p-left">
                              <p className="estimate-pdf" style={{ marginBottom: '0px' }}>&nbsp;</p>
                            </div>
                            <div className="col-8">
                              <p className="estimate-pdf-label">{invoice === 1 ? stData.invoice_label : stData.label == '' || stData.label == null ? <div>&nbsp;</div> : stData.label}</p>
                            </div>
                          </div>
                        </div>

                        <div className="container-pdf">
                          <div className="row w-100">
                            <div className="col-8 p-left">
                              {contact ? (
                                <>
                                  {" "}
                                  <table className="table custom-table-bordered m-bottom-30 ">
                                    <thead className="custom-th-width">
                                      <tr>
                                        <th
                                          scope="col"
                                          className="text-end-pdf custom-bg-grey-pdf"
                                        >
                                          PREPARED FOR
                                        </th>
                                        <th
                                          scope="col"
                                          className="custom-bg-pdf-first custom-width th-contact-width"
                                        >
                                          {contact.first_name}{" "}
                                          {contact.last_name}
                                        </th>
                                      </tr>
                                      <tr>
                                        <th
                                          scope="col"
                                          className="text-end-pdf custom-bg-grey-pdf-middle"
                                        >
                                          EMAIL
                                        </th>
                                        <th
                                          scope="col"
                                          className="custom-bg-pdf-second"
                                        >
                                          {contact.email}
                                        </th>
                                      </tr>
                                      <tr>
                                        <th
                                          scope="col"
                                          className="text-end-pdf custom-bg-grey-pdf-last"
                                        >
                                          CLAIM #
                                        </th>
                                        <th
                                          scope="col"
                                          className="custom-bg-pdf-last"
                                        >
                                          {stData.claim_number}
                                        </th>
                                      </tr>
                                    </thead>
                                  </table>
                                </>
                              )
                                :
                                (
                                  <>
                                    {" "}
                                    <table className="table custom-table-bordered ">
                                      <thead className="custom-th-width">
                                        <tr>
                                          <th
                                            scope="col"
                                            className="text-end-pdf custom-bg-grey-pdf"
                                          >
                                            PREPARED FOR
                                          </th>
                                          <th
                                            scope="col"
                                            className="custom-bg-pdf-first custom-width th-contact-width"
                                          >
                                            {defContactFirstName}{" "}
                                            {defContactLastName}
                                          </th>
                                        </tr>
                                        <tr>
                                          <th
                                            scope="col"
                                            className="text-end-pdf custom-bg-grey-pdf-middle"
                                          >
                                            EMAIL
                                          </th>
                                          <th
                                            scope="col"
                                            className="custom-bg-pdf-second"
                                          >
                                            {defContactEmail}
                                          </th>
                                        </tr>
                                        <tr>
                                          <th
                                            scope="col"
                                            className="text-end-pdf custom-bg-grey-pdf-last"
                                          >
                                            CLAIM #
                                          </th>
                                          <th
                                            scope="col"
                                            className="custom-bg-pdf-last"
                                          >
                                            {stData.claim_number}
                                          </th>
                                        </tr>
                                      </thead>
                                    </table>
                                  </>
                                )

                              }
                            </div>
                            <div className="col-4" style={{ marginTop: "-2px" }}>
                              <table className="table custom-table-bordered ">
                                <thead style={{ width: "130%" }}>
                                  <tr>
                                    <th scope="col" className="text-end-pdf custom-bg-dark-pdf">DATE</th>
                                    <th scope="col" className="custom-bg-pdf w-100" style={{ minWidth: '184px' }}>{moment().format("MM/DD/YYYY")}</th>
                                  </tr>
                                  <tr>
                                    <th scope="col" className="text-end-pdf custom-bg-dark-pdf-middle">{(invoice === 1) ? 'MAT COST #' : 'ORDER #'}</th>
                                    <th scope="col" className="custom-bg-pdf">{stData.ref_id}</th>
                                  </tr>
                                  <tr>
                                    <th scope="col" className="text-end-pdf custom-bg-dark-pdf-middle">FOR</th>
                                    <th scope="col" className="custom-bg-pdf">{category.name}</th>
                                  </tr>
                                  <tr>
                                    <th scope="col" className="text-end-pdf custom-bg-dark-pdf-last">STRUCTURES</th>
                                    <th scope="col" className="custom-bg-pdf custom-last">{stData.structure_count}</th>
                                  </tr>
                                </thead>
                              </table>
                            </div>
                          </div>
                        </div>

                        <div className="container-pdf">
                          <div className="w-100">
                          {propertyOwner?.name && 
                            <p className="pdf-details-pdf loc-sec">
                              {propertyOwner?.name}
                              {
                                stData.location && ` - ${stData.location}`
                              }
                            </p>}
                          </div>
                        </div>
                      </div>:null
                    }
                        <div className={(invoice === 1) ? "container-pdf table-invoice" : "container-pdf"} >
                          <div className="w-100 row">
                            <div className="w-100 m-bottom">
                              {iter ==0?
                                <>
                                <div className={`custom-pdf-bg2-pdf w-100 header-title header-radius ${stData.location ? "border-radius-none" : ""}`}>
                                  <div scope="col" className="text-start-pdf w-100 header-title header-radius">{category.name}</div>
                                </div>
                                <div className="w-100">
                                  <div className="quantity-row-pdf">
                                    <div className="table-pdf text-end-pdf b-left table-header">QUANTITY</div>
                                    <div className="table-pdf col-product table-header">PRODUCT</div>
                                    <div className="table-pdf col-manufacturer table-header t-align-right">MANUFACTURER</div>
                                    <div className="table-pdf table-header t-align-right">COLOR</div>
                                    {invoice === 1 && <div className="table-pdf text-end-pdf table-header t-align-right">TOTAL</div>}
                                  </div>
                                </div>
                                </>:null
                              }
                              {currentItems.map((line_item, line_item_index) => {
                                if (calculateAdjQuantity(line_item) > 0) {
                                  count++;
                                  let subLineItems = line_item?.sub_line_item;
                                  if (!subLineItems || subLineItems == "[]" || subLineItems[0] == "[]") {
                                    subLineItems = [];
                                  }
                                  if (typeof subLineItems == 'string') {
                                    subLineItems = getSantizedSublineItem(subLineItems);
                                  }
                                  consoleLog('subLineItems>', subLineItems);
                                  consoleLog('subLineItems>', typeof subLineItems);
                                  return (<div className="w-100"
                                    key={line_item_index}>
                                    <div className={`quantity-row-pdf ${line_item_index % 2 == 0
                                      ? ""
                                      : "background-table"
                                      }`}>
                                      <div className="table-pdf text-end-pdf b-left">
                                        {line_item?.unit != '' ? calculateAdjQuantity(line_item) + ' ' + line_item.unit : ''}
                                      </div>
                                      <div className="table-pdf col-product">
                                        {line_item?.name}
                                        {subLineItems?.length > 0 && (
                                          <ul className="notes">
                                            {subLineItems.map((note, note_index) => 
                                              note && <li key={note_index}>{note}</li>
                                            )}
                                          </ul>
                                        )}
                                      </div>
                                      <div className="table-pdf col-manufacturer t-align-right-10">
                                        {
                                          line_item?.manufacturer ? line_item?.manufacturer : ''
                                        }
                                      </div>
                                      <div className="table-pdf t-align-right-10">
                                        {line_item?.color}
                                      </div>
                                      {invoice === 1 && <div className="table-pdf text-end-pdf t-align-right-10">{(parseInt(line_item?.remove_prices) === 0 || typeof line_item.remove_prices === 'undefined') && line_item.unit != '' && `$${line_item.net_cost ? Number(line_item.net_cost).toFixed(2) : '0.00'}`}</div>}
                                    </div>
                                  </div>)
                                }
                              })}
                            </div>
                          </div>
                        </div>
                        { iter == pagesBasedOnSingleCat-1 ?
                        <>
                        {invoice === 1 && <div className="container-pdf">
                          <div className="col-8"></div>
                          <div className="col-4 p-right">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col" className="font-total">Total</th>
                                  <th scope="col" className="bg-pdf">${total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</th>
                                </tr>
                              </thead>
                            </table>
                          </div>
                        </div>}
                        <div className="container-pdf" style={{ marginBottom: "50px" }}>
                          <div className="w-100 notes-box">
                            <p style={{ display: "flex" }} className="notes-box-heading"> <img src={NotesIcon} className="notes-icon" />NOTES</p>
                            <p dangerouslySetInnerHTML={{ __html: stData.notes }} className="notes-box-text"></p>
                          </div>
                        </div>
                        </>:null
                      }
                      <PageFooter 
                          stData={stData}
                          contact={contact}
                          defContactPhoneNo={defContactPhoneNo}
                          color={color}
                          pageCount={pageCount}
                          totalPages={totalPages}
                      />
                      </Wrapper>)
                    }
                    return renderedPages;
                  }
                })}
              </MainDiv>)
            })}
          </div>
        </PDFS>)}
    </>
  );
}

const printStyles = `
    @media print {
    
      @page {
        margin: 0.5in; 
      }

      @media print {
    padding: 0 !important; /* Remove padding during print */
    .page-break {
      page-break-before: always;
      break-before: page;
    }
  }
      .print-page-number {
        display: none !important;
      }
      .footer-wrapper{
        position: fixed !important; /* Stick the footer at the bottom */
        bottom: 0 !important;
        left: 0;
        right: 0;
        width: 100%;
        max-width: 100% !important;
        padding: 0 !important; /* Remove padding for print */
      }
      .financial_report_container {
        padding: 0 !important; /* Remove padding for print */
      }
    @media screen {
      .print-page-number {
        display: none !important;
      }
    }
`;

const LoadingContainer = styled.div`
    height:100vh;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    background: transparent;
    .loader{
        -webkit-user-select: none;
        margin: auto;
    }
`
const PDFS = styled.div`
    width:100%;
    height:100%;
    background-color: #CCCCCC;
    padding-bottom: 30px;
    padding-top: 60px;
    .header-contact{
      font-family:Rajdhani;
      font-size:16px;
      font-weight:700;
    }
    .mainDiv {
      width:  816px;
      height: 100%;
      margin: auto;

      @media(max-width: 767px) {
        width: 100%;
        overflow: hidden;
      }
      @media print {
        overflow: hidden; /* Prevent accidental overflow onto other pages */
        margin-top:-39px !important;
        @page {
          size: 8.5in 10in;
          margin: 0.3in 0.3in 0.3in 0.3in;
        }
      }
    }
    .pdf-container > div {
      
    }
      
    .rotate-img{
      transform: rotate(270deg);
      -ms-transform: rotate(270deg);
      -moz-transform: rotate(270deg);
      -webkit-transform: rotate(270deg);
      -o-transform: rotate(270deg);
    }
    .button-section {
      border-radius: 3px;
      cursor: pointer;
      width: 39px;
      height: 39px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #414141;
      margin-right: 10px;

      &:hover {
        opacity: 0.5;
      }
  
      img {
        width: 20px;
        height: 20px;
      }
    }
  
    .button-calculator {
      cursor: pointer;
      width: 39px;
      height: 39px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color:#7cc57c;
      img {
        width: 20px;
        height: 20px;
      }
      &:hover {
        opacity: 0.5;
      }
    }
  
    .button-slip {
      cursor: pointer;
      width: 39px;
      height: 39px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color:#9ac8f1;
      img {
        width: 20px;
        height: 20px;
      }
      &:hover {
        opacity: 0.5;
      }
    }
`;

const MainDiv = styled.div`
  width:100%;
  height:100%;
`;

const Wrapper = styled.div`
  height: 1056px;
  padding: 30px 50px 90px 50px;
  margin-top: 1em;
  margin-bottom: 2em;
  padding: 37px;
  background-color: #ffffff;
  position: relative;
  page-break-after: always !important;

  .container-pdf {
    padding: 0px !important;
  }

  @media print {
    padding: 0 !important; /* Remove padding during print */
  }

  .p-left {
    padding-left: 0px;
  }
  
  .p-right {
    padding-right: 0px;
  }

  .quantity-row-pdf {
    display: -webkit-box;
    height: auto;
  }

  .table-pdf {
    color: #000 !important;
    width: 13%;
    padding: 8px 10px !important;
    border-right: 1px solid #d6e1e5 !important;
    border-bottom: 1px solid #d6e1e5 !important;
    word-wrap: break-word !important;
    text-align: left !important;
  }
  .bg-white{
    background-color: #fff !important;
  }
  .bg-grey{
    background-color: #D6E1E540 !important;
  }
  .table-pdf.col-manufacturer {
    width: 22%;
  }
  .table-pdf.col-product {
    width: 52%;
  }
  .table-invoice .table-pdf.col-product {
    width: 39%;
  }

  .font-total, .bg-pdf  {
    border: none;
    text-align: end;
  }

  .font-total {
    background-color : #212e34 !important;
    color: white !important;
    border-top-left-radius : 5px;
    border-bottom-left-radius : 5px;
  }

  .bg-pdf {
    background-color: #F79425 !important;
    color: #212e34 !important;
    font-size: 14px;
    font-weight: bold;
    border-top-right-radius : 5px;
    border-bottom-right-radius : 5px;
  }

  .b-left {
    border-left: 1px solid #d6e1e5 !important;
  }
  .background-table {
    background-color: #d6e1e540 !important;
  }
  .notes {
    margin-top: 10px;
    list-style-type: none;
    line-height: 22px;
    margin-bottom: 0px;
    padding-left: 35px;
  }
  
  .m-bottom {
    margin-bottom: 20px;
  }
  .m-bottom-30 {
    margin-bottom: 30px;
  }
  .structure-wrapper .category-header {
    margin-top: 30px;
  }
  .structure-wrapper .structure-header + .category-header {
    margin-top: 0;
  }

  .footer-wrapper {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    color: #fff !important;
    margin-top: auto;
    position: absolute;
    bottom: 27px;
    max-width: 743px;
  }

  .width-95-l {
    width: 95%;
    float: left;
  }

  .width-5-r {
    width: 5%;
    float: right;
  }

  .footer-container {
    color: #fff !important;
  }
  
  .footer-bg {
    float: left !important;
    padding: 0px 5px !important;
    background-color: transparent !important;
    color: #ffffff !important;
    font: normal normal normal 12px Rajdhani !important;
    letter-spacing: 0.42px !important;
    font-weight: 500 !important;
   }
  .footer-notes-right {
    flex:2;
    padding: 5px !important;
    background-color: #212E34 !important;
    font: normal normal normal 12px Rajdhani !important;
    letter-spacing: 0.26px !important;
    text-align: end !important;
    color:#fff !important;
    min-height:36px;
    display: flex;
    justify-content: right;
    align-items: center;
  }
  .footer-notes {
    width: 74% !important;
    float: left !important;
    padding: 5px !important;
    font: normal normal normal 12px Rajdhani !important;
    letter-spacing: 0.26px !important;
    color: #fff !important;
  } 
  .custom-bg-pdf {
    font-size: 12px;
  }

  .loc-sec{
    background-color: #151f24 !important;
    padding: 13px 12px;
    color: #ffffff !important;
    margin-bottom: 0;
    border-radius: 13px 13px 0px 0px;
  }

  .border-radius-none {
    border-radius: 0;
  }
  
  .page-number {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font: normal normal normal 12px Rajdhani !important;
    min-height: 36px;
    border-bottom-right-radius: 10px !important;
    min-width: 60px;
  }

  .custom-bg-dark-pdf {
    background-color: #212e34 !important;
  }
  .custom-bg-dark-pdf-middle {
    background-color: #212e34 !important;
  }
  .custom-bg-dark-pdf-last {
    background-color: #212e34 !important;
  }
  .custom-bg-grey-pdf-last{
    background-color: #D6E1E5 !important;
  }
  .custom-bg-grey-pdf-middle{
    background-color: #D6E1E5 !important;
  }
  .custom-bg-grey-pdf{
    background-color: #D6E1E5 !important;
  }
  .table-header{
    font-family:Rajdhani;
    font-size: 11px !important;
    letter-spacing: 0.26px;
    font-weight: bold;
    color:#212E34 ;
    background-color: #D6E1E5;
    text-align: left;
    padding:10px 10px !important
  }
  .t-align-right{
    text-align: right !important;
  }
  .t-align-right-10{
    text-align: right !important;
    padding-right: 10px !important;
  }
  .footer-container-left{
    flex:1.5;
    border-bottom-left-radius: 10px !important;
    display: flex;
    align-items: center;
    background-color: #212E34 !important;
  }
  .footer-container-right{
    flex:1;
    display:flex;
    align-items: center;
    border-bottom-right-radius: 10px !important;
  }
`;