import React, { useEffect, useState, useRef } from "react";
import NotesIcon from "../../assets/Notes_Icon.svg";
export default function EstimateSummary (props){

    return (<div className="container-pdf m-top">
            <div className="w-100 row">
            <div className="col-8 p-left notes-box">
                <p className="notes-box-heading"> <img src={NotesIcon} className="notes-icon" />NOTES</p>
                <p dangerouslySetInnerHTML={{ __html: props.stData.notes }} className="notes-box-text"></p>
            </div>
            <div className="col-4 p-right">
                <table className="table custom-total-table">
                <thead>
                    <tr>
                    <th scope="col" className="font-total total-background-first ">&nbsp;&nbsp;&nbsp;&nbsp;Subtotal</th>
                    <th scope="col" className="amount-first custom-th-total-table">${props.subTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</th>
                    </tr>
                    {props.taxes.length > 0 &&
                    props.taxes.map((tax, i) => {
                        if (parseInt(tax.total) > 0) {
                        return (<tr key={i}>
                            <th scope="col" className="font-total font-total-small total-background">
                            {tax.name}
                            </th>
                            <th scope="col" className="amount">
                            ${tax.total}
                            </th>
                        </tr>)
                        }
                    })}
                    {props.markups.length > 0 &&
                    props.markups.map((markup, i) => {
                        if (markup.total) {
                        return (<tr key={i}>
                            <th scope="col" className={`font-total font-total-small ${i + 1 == props.markups.length ? "total-background-last" : "total-background"}`}>
                            {markup.name}
                            </th>
                            <th scope="col" className="amount">
                            ${markup.total}
                            </th>
                        </tr>)
                        }
                    })}
                    <tr>
                    <th scope="col" className="font-total font-total-background">TOTAL</th>
                    <th scope="col" className="bg-pdf" >${props.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</th>
                    </tr>
                </thead>
                </table>
            </div>
            </div>
        </div>);
}